.dropContainer {
    background-color: #F3F4F8;
    border-radius: 5px;
    border: 1px dashed #ACB2CC;
    height: 16rem;
    /* margin: 2rem 0; */
    /* width: 60%; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.drag {
    background-color: #d6d7da;
}

.centerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & p {
        margin: 0 2rem;
        color: #ACB2CC;
    }
}

.purple {
    color: #8B96E9;
    cursor: pointer;
}