.general-modal-container {
	padding-bottom: 4rem;
	@include sm-h {
		padding-bottom: 2rem;
	}
	.close-conainer {
		text-align: right;
		button {
			padding-right: 1.5rem;
			padding-top: 1rem;
		}
	}

	.title {
		font-size: 2.2rem;
		color: $primary-color;
		position: relative;
		padding-left: 2.6rem;
		margin-top: 1rem;
		&::before {
			content: "";
			position: absolute;
			left: -1rem;
			top: 50%;
			margin-top: -1.2rem;
			height: 2.3rem;
			width: 2.1rem;
			background-color: $primary-color;
		}
	}
	.subtitle {
		margin-top: 2rem;
		margin-left: 2.6rem;
	}
	.body {
		.buttons {
			text-align: center;
			padding-top: 4.6rem;
			@include sm-h {
				padding-top: 2rem;
			}
			.btn {
				min-width: 14.4rem;
			}
		}
	}
}
