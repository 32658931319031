// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: "allroundgothic";
    src: font("allroundgothic/allroundgothic_bold_macroman/allroundgothic-bold-webfont.woff2") format("woff2"),
        font("allroundgothic/allroundgothic_bold_macroman/allroundgothic-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "allroundgothic";
    src: font("allroundgothic/allroundgothic_book_macroman/allroundgothic-book-webfont.woff2") format("woff2"),
        font("allroundgothic/allroundgothic_book_macroman/allroundgothic-book-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "allroundgothic";
    src: font("allroundgothic/allroundgothic_medium_macroman/allroundgothic-medium-webfont.woff2") format("woff2"),
        font("allroundgothic/allroundgothic_medium_macroman/allroundgothic-medium-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
/* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/3edd9e");

@font-face {
    font-family: "NeueHaasUnicaW1G";
    src: font("NeueHaas/webFonts/NeueHaasUnicaW1GBold/font.woff2") format("woff2"),
        font("NeueHaas/webFonts/NeueHaasUnicaW1GBold/font.woff") format("woff");
    font-weight: 700;
}
@font-face {
    font-family: "NeueHaasUnicaW1G";
    src: font("NeueHaas/webFonts/NeueHaasUnicaW1GRegular/font.woff2") format("woff2"),
        font("NeueHaas/webFonts/NeueHaasUnicaW1GRegular/font.woff") format("woff");
    font-weight: 400;
}
@font-face {
    font-family: "NeueHaasUnicaW1G";
    src: font("NeueHaas/webFonts/NeueHassUnicaW1GMedium/font.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "NeueHaasUnicaW1G";
    src: font("NeueHaas/webFonts/NeueHaasUnicaW1GLight/font.woff2") format("woff2"),
        font("NeueHaas/webFonts/NeueHaasUnicaW1GLight/font.woff") format("woff");
    font-weight: 300;
}
@font-face {
    font-family: "Assistant";
    src: local("Assistant"), url(../../Assets/fonts/Assistant/static/Assistant-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Assistant";
    src: local("Assistant"), url(../../Assets/fonts/Assistant/static/Assistant-Bold.ttf) format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Assistant";
    src: local("Assistant"), url(../../Assets/fonts/Assistant/static/Assistant-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

// Neue Haas Text Pro

// Extra Light
@font-face {
    font-family: "NeueHassTextPro";
    src: font("NeueHaasTextPro/NeueHaasGroteskDisplayPro35ExtraLight/font.woff2") format("woff2"),
        font("NeueHaasTextPro/NeueHaasGroteskDisplayPro35ExtraLight/font.woff") format("woff");
    font-weight: 200;
}

// Normal
@font-face {
    font-family: "NeueHassTextPro";
    src: font("NeueHaasTextPro/NeueHaasGroteskTextPro55Roman/font.woff2") format("woff2"),
        font("NeueHaasTextPro/NeueHaasGroteskTextPro55Roman/font.woff") format("woff");
    font-weight: 400;
}

// Medium
@font-face {
    font-family: "NeueHassTextPro";
    src: font("NeueHaasTextPro/NeueHaasGroteskTextPro65Medium/font.woff2") format("woff2"),
        font("NeueHaasTextPro/NeueHaasGroteskTextPro65Medium/font.woff") format("woff");
    font-weight: 500;
}

// Bold
@font-face {
    font-family: "NeueHassTextPro";
    src: font("NeueHaasTextPro/NeueHaasGroteskTextPro75Bold/font.woff2") format("woff2"),
        font("NeueHaasTextPro/NeueHaasGroteskTextPro75Bold/font.woff") format("woff");
    font-weight: 700;
}

// Black

@font-face {
    font-family: "NeueHassTextPro";
    src: font("NeueHaasTextPro/NeueHaasGroteskDisplayPro95Black/font.woff2") format("woff2"),
        font("NeueHaasTextPro/NeueHaasGroteskDisplayPro95Black/font.woff") format("woff");
    font-weight: 900;
}


// Fasthand

// Normal
@font-face {
    font-family: "Fasthand";
    src: font("/Fasthand/Fasthand-Regular.ttf");
    font-weight: 400;
}

// @font-face {
// 	font-family: "NeueHassTextPro";
// 	src: font("NeueHaasTextPro/NeueHaasGroteskDisplayPro35ExtraLight/font.woff2") format("woff2"),
// 		font("NeueHaasTextPro/NeueHaasGroteskDisplayPro35ExtraLight/font.woff") format("woff");
// 	font-weight: 400;
// }
