.file-upload-modal {
	max-width: 73rem;

	.file-name {
		background-color: #f4f4f4;
		margin-right: -1rem;
		margin-left: -1rem;
		text-align: center;
		font-size: 1.4rem;

		padding: 1rem;
		margin-top: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.file-uploader-container {
		margin: 6rem 5.8rem;
	}
}
