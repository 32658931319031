@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap");

.paymentScreen {
	$border-thin-color: #cecece;
	padding-top: 2.48rem;
	flex: 1;
	font-family: $font-assistant;
	padding-top: 0 !important;
	color: #3e4049;

	.payment-loader-container {
		background: #00000073;
		display: flex;
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 9999999999;
	}

	.pci-img {
		padding: 15px;
		& img {
			width: 120px;
		}
	}

	.question-icon:after {
		content: "";
		position: absolute;
		width: 2.1rem;
		height: 2.1rem;
		top: 6px;
		background-image: image("icons/question_mark_grey.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.page-container {
		margin-top: 2.48rem;
		background-color: #e8ecff;
		padding-left: 3.2rem;
		padding-right: 3.2rem;
		position: relative;
		padding-top: 3.7rem;
		padding-bottom: 3.4rem;
		border-radius: 0.5rem;
		box-shadow: 0px 0.1rem 0.1rem #00000026;
		border: 1px solid #f1f1f1;
		flex: 1;
		margin-top: 0 !important;
		min-height: 100%;

		// background-image: image("other/Mask Group 2472.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-attachment: fixed;
		background-position: left -20rem;

		.info-container {
			font-size: 1.4rem;
			font-family: $font-assistant;
			display: flex;
			justify-content: space-between;

			.info-main {
				text-align: center;
				letter-spacing: 0.6px;
				color: #9999ff;
				opacity: 1;
				font-size: 3rem;
				font-family: allroundgothic-medium;
			}
			.info-sub {
				text-align: center;
				letter-spacing: 0.4px;
				color: #3e4049;
				opacity: 1;
				font-size: 4rem;
				// margin-inline-start: 40rem;
				// margin-inline-end: 40rem;
			}
			.title-upgrade {
				font-size: 2.5rem;
				line-height: 29px;
			}

			.title-reg {
				font-family: allroundgothic-medium;
				font-weight: 600;
				font-size: 3.7rem;
			}
		}
		.plans-board {
			display: flex;
			justify-content: center;
			margin-top: 2.7rem;

			.plan-box {
				background: #ffffff 0% 0% no-repeat padding-box;
				box-shadow: 0px 3px 6px #6565bc47;
				border: 2px solid #e8ecff;
				border-radius: 16px;
				opacity: 1;
				padding: 1.5rem 3rem 3rem 3rem;
				max-width: 35rem;
				margin-left: 2rem;
				flex: 1;

				.plan-title {
					text-align: left;
					font-family: allroundgothic-bold;
					font-size: 27px;
					letter-spacing: -0.6px;
					opacity: 1;
					color: #9999ff;

					@include gt-md {
						font-size: 36px;
					}
				}
				.plan-title-orange {
					color: #fc9f30;
				}
				.plan-title-black {
					color: #3e4049;
				}
				.plan-summary {
					text-align: left;
					font-size: 1.6rem;
					font-family: $font-assistant;
					letter-spacing: 0px;
					color: #3e4049;
					opacity: 1;
				}
				.price {
					text-align: left;
					font-family: allroundgothic-medium;
					font-size: 24px;
					letter-spacing: -0.45px;
					color: #3e4049;
					opacity: 1;
					margin-top: -9px;
					span {
						color: #a9a9a9;
					}

					@include gt-md {
						font-size: 30px;
					}
				}
				.plan-summary {
					padding-top: 0.5rem;
					font-size: 1.7rem;
					min-height: 11rem;
					margin-top: 1.1rem;
					line-height: 18px;
				}
				.buy-plan {
					// margin-top: 2rem;
					button {
						font-family: $font-assistant;
						font-size: 1.75rem;
						font-weight: 700;
						color: #132afb;
						width: 100%;
						height: 40px;
						text-align: center;
						letter-spacing: 0.5px;
						color: #ffffff;
						opacity: 1;
						// padding-top: 6px;
						border-radius: 35px;
						@include gt-md {
							height: 44px;
						}
					}
					.btn-start {
						background: transparent linear-gradient(90deg, #f5ad58 0%, #fc9f30 100%) 0% 0% no-repeat padding-box;
						box-shadow: 0px 2px 1px #6565bc33;
						border: 1px solid #ffffff;
					}
					.btn-downgrade {
						color: #8d8b8b;
						background: transparent linear-gradient(90deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
						box-shadow: 0px 2px 1px #6565bc33;
						border: 2px solid #a9a9a9;
						&:hover {
							// color: #ffffff;
						}
					}
					.btn-active {
						color: #9999ff;
						background: transparent linear-gradient(90deg, #f5f6ff 0%, #f5f6ff 100%) 0% 0% no-repeat padding-box;
						box-shadow: none;
						border: 1px solid #ffffff;
					}
				}
				.plan-includes {
					.includes {
						font-weight: bold;
						margin-top: 4rem;
						font-size: 2rem;
						letter-spacing: 0px;
						color: #3e4049;
						opacity: 1;
						span {
							color: #9999ff;
						}
					}
					.include-list {
						list-style: none;
						li {
							margin-top: 1.5rem;
							position: relative;
							display: flex;
							text-align: left;
							font-size: 2rem;
							letter-spacing: -0.3px;
							color: #3e4049;
							opacity: 1;
							.prop-icon:after {
								content: "";
								position: absolute;
								width: 2.1rem;
								height: 2.1rem;
								left: -4rem;
								top: 4px;
								background-image: image("icons/Group 4210.svg");
								background-repeat: no-repeat;
								background-size: contain;
							}
						}
					}
				}
			}
		}
	}
}

.pay-page {
	font-family: $font-assistant;
	height: 100%;
	background-color: #e8ecff;
	// background-image: image("other/Mask Group 2472.svg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: left -20rem;

	.info-container {
		// background-image: image("other/Mask Group 2472.svg");
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: left -20rem;
		margin: 5rem 15rem;
	}
	.pay-header {
		padding-bottom: 2rem;
	}
	.pay-now {
		text-align: right;
		button {
			padding: 5px 30px;
			margin-top: 10px;
		}
	}
	.box {
		background: #fff;
		box-shadow: 0px 3px 6px #6565bc47;
		border: 1px solid #e8ecff;
		border-radius: 6px;
		opacity: 1;
		padding: 1.5rem 3rem;
	}
	.upgrade-plan-box {
		height: 36.7rem;
		align-self: flex-start;
		text-align: left;
		letter-spacing: 0px;
		color: #3e4049;
		padding-bottom: 6.2rem;
		// height: 100%;
		.choose-plan-header {
			display: flex;
			justify-content: space-between;
			.upgrade-txt {
				font-size: 2.5rem;
			}
			.change-plan {
				align-self: center;
				button {
					text-decoration: underline;
				}
			}
		}
		.plan-txt {
			font-size: 2.5rem;
			font-weight: bold;
		}
		.plan-descr-txt {
			border-top: 1px solid #9999ff;
			border-bottom: 1px solid #9999ff;
			padding: 2rem 0 6rem 0;
			margin: 3rem 0 2rem 0;
			span {
				font-size: 1.6rem;
			}
		}
		.pay-line-price {
			display: flex;
			justify-content: space-between;
			// text-align: left;
			font-size: 2.5rem;
			letter-spacing: 0px;
			color: #3e4049;
			opacity: 1;
			.pay-price {
				font-weight: 600;
			}
		}
	}
	.contact-box {
		min-width: 37rem;
		height: 17.4rem;
		align-self: flex-start;
		letter-spacing: 0px;
		color: #3e4049;
		font-size: 2.5rem;
		font-weight: bold;
		.title {
			border-bottom: 1px solid #9999ff;
			display: flex;
			justify-content: space-between;
			padding-bottom: 2px;
		}
		.addVestingBtn img {
			width: 21px;
		}
		.contact-info {
			margin-top: 3rem;
			display: flex;
			justify-content: space-between;
			text-align: left;
			font-size: 1.6rem;
			color: #3e4049;
			.contact-prop {
				display: flex;
				align-items: center;
				font-weight: 400;
				div:first-child {
					margin-right: 2rem;
				}
				.prop-val {
					min-width: 5rem;
				}
			}
		}
	}
	.payment-box {
		margin-top: 19px;
		min-width: 37rem;
		height: 17.4rem;
		label {
			width: 100%;
		}

		.payment-info {
			font-size: 2.5rem;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #9999ff;
			padding-bottom: 2px;
			img {
				width: 3rem;
				margin-left: 10px;
				margin-top: -10px;
			}
			.addVestingBtn img {
				width: 21px;
			}
		}

		.credit-card-add {
			display: flex;
			padding-top: 1.9rem;
			.card-img-btn {
				width: 12rem;
			}
			.card-txt-btn {
				padding-left: 2rem;
				color: #9999ff;
				font-weight: 600;
				font-size: 1.8rem;
				span {
					padding-right: 1rem;
				}
				span:nth-child(2) {
					text-decoration: underline;
				}
			}
			.credit-card-details {
				padding-left: 2rem;
				padding-top: 1rem;
			}
		}
	}
}

.StripeElement {
	display: block;
	margin: 1px 0 15px 0;
	background-color: #fff;
	padding: 10px 14px;
	font-size: 1em;
	font-family: "Source Code Pro", monospace;
	border: 1px solid $gray2;
	border-radius: 0.3rem;
	outline: 0;
}
.billing-contact-dialog {
	.contact-header {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #9999ff;
		// padding: 1rem 2rem;
		margin: 1rem 2rem;
		padding-bottom: 1rem;
		// margin: 0 2rem ;
		.contact-title {
			font-size: 2rem;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
		}
	}
	.contact-form {
		margin: 4rem 13rem;
		.control-group {
			padding-top: 2.5rem;
		}
		.buttons-group {
			text-align: center;
			padding-top: 5rem;
			button {
				min-width: 13rem;
			}
		}
	}
}

.credit-dialog {
	.credit-header {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #9999ff;
		padding: 1rem 1rem 2rem 1rem;

		.credit-title {
			font-size: 2rem;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
		}
	}

	.form-wrapper {
		padding: 5rem 9rem;
		text-align: -webkit-center;
		form {
			.form-inner-wrapper {
				padding: 4rem 8rem 2rem 8rem;
				text-align: left;
				background-color: #f3f5fd;
				border: 1px solid #b8b8fa80;
				box-shadow: 0px 3px 6px #6565bc47;
				border-radius: 6px;
			}
			.pci-note {
				display: flex;
				margin-top: 0.5rem;
				background-color: #f3f5fd;
				box-shadow: 0px 3px 6px #6565bc47;
				border: 1px solid #b8b8fa80;
				border-radius: 6px;
				text-align: left;
				font-size: 13px;
				> div {
					margin: 6px;
					align-self: center;
				}
				& img {
					width: 60px;
				}
			}
		}

		.lbl-input {
			padding-left: 3px;
		}
		.card-numbers {
			padding-top: 1.5rem;
		}
		.card-cvc-epiry {
			display: flex;
			justify-content: space-between;
			div {
				width: 100px;
			}
		}
		.stripe-error {
			color: red;
		}
		.pay-butt {
			padding: 3rem 7rem 0 7rem;
			position: relative;
			.btn-pay {
				width: 30rem;
			}
		}
	}
}
// .pay-dialog {

//   .modal-body {
//     padding: 0;
//   }
//   .close-conainer {
//     right: 0;
//     position: absolute;
//     button {
//
//       padding-right: 1.5rem;
//       padding-top: 1rem;
//     }
//   }
// }
.back-to-plans button {
	text-align: left;
	text-decoration: underline;
	font-size: 2rem;
	letter-spacing: 0px;
	color: #3e4049;
	font-weight: bold;
}
.currency-selector-wrapper {
	text-align: center;
	color: #3e4049;
	font-size: 1.6rem;
	font-weight: 600;
	white-space: nowrap;
	.currency-selector {
		background: #ffffff;
		box-shadow: 2px 2px 0.75px #6565bc33;
		border: 1px solid #bebec2;
		border-radius: 4px;
		padding: 4px;
		button {
			text-align: center;
			font-size: 2rem;
			letter-spacing: 0px;
			color: #3e4049;
			width: 45px;
			height: 40px;
			display: inline;
			font-weight: bold;
		}
		.currency-selected {
			background: #3e4049;
			box-shadow: 0px 3px 6px #6565bc47;
			border-radius: 4px;
			color: #fff;
		}
	}
}
.storage-plan-dialog {
	font-family: $font-assistant;
	.close-dialog {
		text-align: right;
	}
	.dialog-content {
		margin: 3rem 6rem;
	}
	.top-title {
		text-align: left;
		color: #9999ff;
		font-size: 2.3rem;
		font-weight: bold;
	}
	.sub-title {
		text-align: left;
		font-size: 2.3rem;
		margin-bottom: 3rem;
	}
	.statistic-row {
		border-top: 2px solid #e8ecff;
		display: flex;
		padding: 3rem 4rem 3rem 4rem;
	}
	.statistic-row-bottom {
		border-bottom: 2px solid #e8ecff;
	}

	.view-more-line {
		padding: 2rem 4rem;
		font-size: 1.5rem;
		color: #4d4d4d;
		text-align: left;

		font-weight: bold;
		border-bottom: 2px solid #e8ecff;

		.features-list-wrapper {
			max-height: 20rem;
			display: flex;
			flex-direction: column;
			.features-list {
				list-style: none;
				margin-right: 2rem;
				li {
					margin-top: 1rem;
					position: relative;
					display: flex;
					text-align: left;
					font-size: 1.5rem;
					letter-spacing: -0.3px;
					color: #4d4d4d;
					opacity: 1;
					.prop-icon:after {
						content: "";
						position: absolute;
						width: 2.1rem;
						height: 2.1rem;
						left: -4rem;
						top: 8px;
						background-image: image("icons/Group 4210.svg");
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}

	.submit-form {
		padding-top: 2rem;
		display: flex;
		justify-content: space-around;
		div {
			display: flex;
			gap: 2rem;
		}
	}
	.progress {
		width: 100%;
		height: 10px;
		overflow: hidden;
		background-color: #f5f5f5;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	}
	.progress-bar {
		float: left;
		width: 0%;
		height: 100%;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
		text-align: center;
		background-color: #9d98fd;
		-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
		-webkit-transition: width 0.6s ease;
		-o-transition: width 0.6s ease;
		transition: width 0.6s ease;
	}
	.worn-ico {
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 50%;
		background-color: #f81515;
		position: absolute;
		border: 1px solid #e8ecff;
		opacity: 1;
	}

	.worn-ico::before {
		content: "!";
		top: -0rem;
		left: 0.5rem;
		position: absolute;
		font-size: 1.3rem;
		font-family: serif;
		font-family: $font-assistant;
		color: white;
	}
	.progress-props {
		width: 100%;
		line-height: 1.2;
		margin-left: 3rem;
		.progress-numbers {
			font-size: 2.5rem;
			color: #3e4049;
		}
		.stack-progress {
			display: flex;
			justify-content: space-between;
			.stack-worning {
				padding-right: 3rem;
				position: relative;
				color: red;
				font-size: 1.25rem;
				.stack-ico {
					right: 0;
					top: 0;
				}
			}
		}

		.progress-descr {
			display: flex;
			justify-content: space-between;

			.progress-name {
				color: #000;
				padding-bottom: 0.5rem;
			}
			.progress-worning {
				padding-bottom: 0.5rem;
				font-size: 1.25rem;
				position: relative;
				padding-right: 2rem;
				color: red;
			}
			.progress-ico {
				right: 0;
				top: 0;
			}
		}
		.progress-used {
			text-align: end;
			color: #3e4049;
			padding-right: 0.4rem;
			font-size: 1.25rem;
		}
	}
}
