.panel-x {
	flex: 1;
	height: 100%;
	background-color: #fff;
	border: 0.1rem solid #e9e9e9;
	border-radius: 0.5rem;
	padding: 2rem 0 1rem 0;
	display: flex;
	flex-direction: column;

	.panel-head {
		border-bottom: 0.3rem solid #e9e9e9;
		padding: 1.6rem;
		padding-top: 0;
		height: 5.7rem;
	}
}
