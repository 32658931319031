.ro-form-field {
	.ro-label {
		font-size: 1.4rem;
		color: #000;
		border-bottom: 0.1rem solid #d3d4db;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		padding-left: 0.5rem;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ro-value-container {
		display: flex;
		.ro-prefix {
			flex-basis: 7rem;
			color: $text-color;
			padding-left: 0.5rem;
		}
		.ro-value {
			flex: 1;
			color: $text-color;
			padding-left: 0.5rem;
		}
	}
}
