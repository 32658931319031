.radio-button {
	width: 2.6rem;
	height: 2.6rem;

	border: 2px solid $color-primary;
	border-radius: 50%;
	display: inline-block;
	margin-right: 1.5rem;
	vertical-align: middle;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;
	background-color: #fff;

	&:after {
		content: "";
		display: block;
		// position: absolute;
		width: 2.2rem;
		height: 2.2rem;
		border: 0.31rem solid #fff;
		top: 0rem;
		left: 0rem;
		transition: all 250ms ease-in-out;
		opacity: 0;
		background-color: $color-primary;
		border-radius: 50%;
		box-sizing: border-box;
	}
	&.checked:after {
		opacity: 1;
	}

	&.disabled {
		cursor: default;
	}
}
