h2 {
    font-size: 1.8rem;
    padding: 1rem 0;
}

.amainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.mainContainer {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.btnHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 3rem 8rem 0 0;
}


.pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 3rem 0;
}

.headerMain {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #D0D4E4;
    width: 100%;
    padding-bottom: 1rem;

    & h3 {
        align-self: self-end;
        margin: 0;
        padding: 0 0 0 3rem;
    }

    & img {
        height: 4rem;
    }
}

.pageBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.importFiles {
    display: flex;
    flex-direction: row;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.importContainer {
    margin: 2rem 0;
}

/* .dataSource {} */

.settingSection {
    width: 100%;
    max-width: 120rem;
    align-self: center;
    margin-top: 2rem;

    & h2 {
        font-size: 2.2rem;
    }
}