.terminationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 2rem;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 46rem;
    height: 10rem;
    margin-left: 1rem;
    border-bottom: 1px solid #acb2cc;
}

.inputs {
    padding: 2rem 0 1rem;
    margin-left: 0;

    .label {
        color: #979DB5;
        margin: 2rem 0 0.4rem 0;
        display: flex;
        justify-content: space-between;
        font-size: 1.3rem;
        align-items: flex-end;
    }
}

.buttons {
    border-top: 1px solid #acb2cc;
    margin-left: 1rem;
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 46rem
}