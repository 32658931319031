.hint {
	width: 1.16rem;
	height: 1.16rem;
	background-size: contain;
	margin-left: 0.8rem;
	border-radius: 50%;
	display: inline-block;
	background-image: image("icons/Group 1918.svg");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	margin-top: -0.8rem;
}
