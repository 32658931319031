.app-menu-toggle {
	position: relative;
	width: 0.6rem;
	height: 3rem;
	text-align: center;
	padding: 0;
	img {
		z-index: 2;
		position: relative;
	}
	&:before {
		position: absolute;
		content: "";
		top: 0;
		left: -50%;
		transform: translateX(-1.2rem);
		width: 3.4rem;
		height: 3.4rem;
		background-color: #d0d3dd;
		z-index: 1;
		border-radius: 50%;
		transition: opacity 250ms ease-in-out;
		opacity: 0;
	}
	&:not([disabled]) {
		&.active,
		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}
	&:is([disabled]) {
		opacity: 0.2;
	}
}

.app-menu {
	// padding: 0rem 2.5rem ;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	width: 20rem;
	.app-menu-item-container {
		padding: 1.2rem 2.5rem;
		//margin: 1.3rem 0;
		display: flex;
		font-size: 1.2rem;
		color: #000;
		align-items: center;
		width: 100%;

		&:hover {
			background-color: #f1f1f2;
		}
		img {
		}
		.app-menu-icon {
			width: 2.6rem;
			margin-right: 2.3rem;
			text-align: right;
		}
	}
}
