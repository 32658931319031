.room-members-list {
	min-height: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
	.table-wrap {
		padding: 0 1.6rem 0 0.62rem;
	}
	.data-table {
		.dt-row {
			height: 6.5rem;
		}
		.icon-col {
			text-align: center;
			flex-basis: 7rem !important;
			img {
				height: 2.9rem;
			}
		}
		.name-col {
			flex: 1;
			flex-shrink: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.email-col {
			flex: 1;
			flex-shrink: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.permissions-col {
			flex-basis: 15rem;
			text-align: center;
		}
		.status-col {
			flex-basis: 8rem;
			text-align: center;
			img {
				max-height: 1.9rem;
			}
		}
		.exit-col {
			flex-basis: 6rem;
			text-align: center;
			img {
				height: 1.9rem;
			}
		}
	}
}
