.data-table {
	width: 100%;
	display: grid;
	&.last-row-border {
		.dt-row:last-child {
			border-bottom: 0.1rem solid #e1e1e1;
		}
	}
	.dt-header-row {
		font-size: 1.4rem;
		color: #000;
		//padding: 1.8rem 0rem;
		border-bottom: 0.1rem solid #e1e1e1;

		display: flex;
		align-items: center;
		& > div {
			padding: 1.7rem 0.5rem;

			//border:1px solid #000;
			box-sizing: content-box;
			min-width: 0;
		}
	}
	.dt-row {
		display: flex;

		& + .dt-row {
			border-top: 0.1rem solid #e1e1e1;
		}
		& > div {
			padding: 1.8rem 0.5rem;
			// background-color: rgb(219, 161, 216);
			// border:1px solid #000;
			box-sizing: content-box;
			min-width: 0;
		}
	}
}
