.company-profile {
	font-family: $font-assistant;
	padding-bottom: 2.48rem;
	.sub-title {
		font-size: 2.2rem;
		color: $primary-color;
		position: relative;

		display: flex;
		align-items: center;
		margin-bottom: 2.1rem;
		img {
			margin-right: 1.3rem;
		}
	}

	.cp {
		&-form {
			background-color: #fff;
			padding: 3.36rem 3.3rem;
			position: relative;
			border-radius: 0.5rem;
			box-shadow: 0px 0.1rem 0.1rem #00000026;
			border: 0.1rem solid #f1f1f1;

			@include lt-md {
				padding: 1.5rem;
			}

			.app-date-picker {
				width: 100%;
				input {
					border: 1px solid #d7ddf1;
					border-radius: 0.3rem;
					width: 100% !important;
				}
			}
			.form-input-group {
				label {
					font-size: 1.4rem;
					color: #000;
					position: relative;
					padding-left: 0.5rem;
				}
				&.required {
					label:after {
						font-size: 1.6rem;
						color: #000;
						content: "*";
						position: absolute;
						right: -1rem;
						top: -0.3rem;
					}
				}
				.sub {
					color: #757575;
					font-size: 1.2rem;
					text-align: right;
					padding-right: 0.5rem;
				}
			}

			.form-line {
				display: flex;
				margin-bottom: 2.7rem;
				color: #000;

				font-size: 1.4rem;
				align-items: stretch !important;
				gap: 2.2rem;
				@include lt-md {
					flex-direction: column;
				}
				&.has-sub-text {
					margin-bottom: 0.8rem;
				}
				.form-cell {
					flex: 0.25;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					&-x2 {
						flex: 0.5;
					}
					.form-cell-children {
						// padding: 0 2.2rem;
					}
					&:last-child {
						.form-cell-children {
							padding-right: 0;
						}
					}
					&:first-child {
						.form-cell-children {
							padding-left: 0;
						}
					}
					&:only-child {
						.form-cell-children {
							// padding-right: 2.2rem;
						}
					}
				}
			}
			.mobile-container {
				display: flex;
				.mobile {
					flex: 1;
				}
				.dialing-code {
					width: 8.3rem;
					margin-right: 0.9rem;
				}
			}
			.upload-id-container {
				margin-top: 0.7rem;
				button span {
					text-decoration: underline;
					margin-left: 1rem;
					color: #000;
				}
			}
			.file-uploader-container {
				width: 48%;
				height: 12.05rem;
				img {
					width: 4rem;
				}
			}

			.files-list {
				height: 12.05rem;
				width: 24rem;
				padding: 0 0rem;
				padding-left: 2rem;
			}
		}
	}
	.buttons {
		text-align: center;
		padding-top: 6.6rem;
		padding-bottom: 1rem;
		.btn {
			width: 14.4rem;
		}
	}

	.view-more-line {
		text-align: right;
	}
}

.plans-board {
	display: flex;
	justify-content: center;
	margin-top: 2.7rem;

	.plan-box {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #6565bc47;
		border: 2px solid #e8ecff;
		border-radius: 16px;
		opacity: 1;
		padding: 1.5rem 3rem 3rem 3rem;
		max-width: 35rem;
		margin-left: 2rem;
		flex: 1;

		.plan-title {
			text-align: left;
			font-family: allroundgothic-bold;
			font-size: 27px;
			letter-spacing: -0.6px;
			opacity: 1;
			color: #9999ff;

			@include gt-md {
				font-size: 36px;
			}
		}
		.plan-title-orange {
			color: #fc9f30;
		}
		.plan-title-black {
			color: #3e4049;
		}
		.plan-summary {
			text-align: left;
			font-size: 1.6rem;
			font-family: $font-assistant;
			letter-spacing: 0px;
			color: #3e4049;
			opacity: 1;
		}
		.price {
			text-align: left;
			font-family: allroundgothic-medium;
			font-size: 24px;
			letter-spacing: -0.45px;
			color: #3e4049;
			opacity: 1;
			margin-top: -9px;
			span {
				color: #a9a9a9;
			}

			@include gt-md {
				font-size: 30px;
			}
		}
		.plan-summary {
			padding-top: 0.5rem;
			font-size: 1.7rem;
			min-height: 11rem;
			margin-top: 1.1rem;
			line-height: 18px;
		}
		.buy-plan {
			// margin-top: 2rem;
			button {
				font-family: $font-assistant;
				font-size: 1.75rem;
				font-weight: 700;
				color: #132afb;
				width: 100%;
				height: 40px;
				text-align: center;
				letter-spacing: 0.5px;
				color: #ffffff;
				opacity: 1;
				// padding-top: 6px;
				border-radius: 35px;
				@include gt-md {
					height: 44px;
				}
			}
			.btn-start {
				background: transparent linear-gradient(90deg, #f5ad58 0%, #fc9f30 100%) 0% 0% no-repeat padding-box;
				box-shadow: 0px 2px 1px #6565bc33;
				border: 1px solid #ffffff;
			}
			.btn-downgrade {
				color: #8d8b8b;
				background: transparent linear-gradient(90deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
				box-shadow: 0px 2px 1px #6565bc33;
				border: 2px solid #a9a9a9;
				&:hover {
					// color: #ffffff;
				}
			}
			.btn-active {
				color: #9999ff;
				background: transparent linear-gradient(90deg, #f5f6ff 0%, #f5f6ff 100%) 0% 0% no-repeat padding-box;
				box-shadow: none;
				border: 1px solid #ffffff;
			}
		}
		.plan-includes {
			.includes {
				font-weight: bold;
				margin-top: 4rem;
				font-size: 2rem;
				letter-spacing: 0px;
				color: #3e4049;
				opacity: 1;
				span {
					color: #9999ff;
				}
			}
			.include-list {
				list-style: none;
				li {
					margin-top: 1.5rem;
					position: relative;
					display: flex;
					text-align: left;
					font-size: 2rem;
					letter-spacing: -0.3px;
					color: #3e4049;
					opacity: 1;
					.prop-icon:after {
						content: "";
						position: absolute;
						width: 2.1rem;
						height: 2.1rem;
						left: -4rem;
						top: 4px;
						background-image: image("icons/Group 4210.svg");
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}
}

.payment-loader-container {
	background: #00000073;
	display: flex;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999999999;
}
