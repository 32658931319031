@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap");

.contactUsScreen {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding-block: 0 !important;
	font-family: $font-assistant;
	font-size: 1.8rem;
	overflow-y: auto;

	.contactus-page {
		display: flex;
		justify-content: center;
		min-height: 100%;
		width: 100%;
		max-width: 140rem;
		background-image: image("other/Group 4270.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position-y: -80px;

		.page-content {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 3rem 0 9rem 0;

			.left {
				width: 60%;

				.back button {
					text-align: left;
					font-size: 2rem;
					letter-spacing: 0px;
					color: #3e4049;

					&:hover {
						text-decoration: underline;
					}
				}
				.left-title {
					font-family: allroundgothic-medium;
					font-size: 4.8rem;
					padding-top: 2rem;
					opacity: 1;
					color: #9999ff;
				}
				.left-content {
					width: 45rem;
				}
			}
			.right {
				padding-top: 6rem;
				width: 40%;

				.content-box {
					background-color: #fff;
					box-shadow: 0px 3px 6px #6565bc47;
					border-radius: 5px;
					border: 2px solid #e8ecff;
					width: 100%;

					.thanks {
						text-align: center;

						.title {
							font-family: allroundgothic-bold;
							font-size: 4.8rem;
							opacity: 1;
							color: #9999ff;
						}
						.content {
							max-width: 30rem;
						}
					}
				}
			}
			.contact-form {
				.control-group {
					padding-top: 2.5rem;
				}
				.buttons-group {
					text-align: center;
					padding-top: 5rem;
					button {
						min-width: 13rem;
					}
				}
			}
		}
	}
}
