.app-grid {
	$border-thin-color: #cecece;
	width: 100%;
	.grid-title {
		background-color: $primary-color;
		color: #fff;
		width: 13.5rem;
		text-align: center;
		border: 0.1rem solid $border-thin-color;
		padding: 1rem;
		font-weight: normal;
	}
	td,
	.grid-cell {
		color: #565656;

		border: 0.1rem solid $border-thin-color;
		padding: 2rem 1rem;
	}

	.total td {
		border-top: 2px solid $border-thin-color;
	}
}
