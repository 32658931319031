.loader {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	.lds-dual-ring {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	.lds-dual-ring:after {
		content: " ";
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid $primary-color;
		border-color: $primary-color transparent $primary-color transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
.loader-container {
	// height: calc(100vh);
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// justify-content: center;
	background: $background1;
	display: flex;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999999999;
}

.loader-wrapper {
	background: $color1;
}
