// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.room {
	// height: calc(100vh - 20rem);
	display: flex;
	flex-direction: column;

	.scrollable-table-body {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex-shrink: 1;

		// overflow-y: auto;
		// overflow-x: hidden;
	}
	.toolbar {
		@extend .toolbar;
		.data-room-title {
			cursor: pointer;
		}
		.room-settings-toggle {
			background-color: $primary-color;
			width: 4.714rem;
			height: 3.66rem;
			padding: 0;
			line-height: 1;
			background-image: image("icons/Group 1911.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 2rem 2rem;
			vertical-align: middle;
			border-radius: 0.3rem;
			margin-left: 0.5rem;
			&:hover {
				background-color: $primary-color-light;
			}
		}
	}
	main {
		display: flex;
		flex: 1;
		// padding: 2rem 0;
		min-height: 0; // Prevents overflow of the box
		overflow: auto;
		.folders-tree-container {
			width: 20.7rem;
			flex: none;
			background-color: $primary-color;
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			padding: 1.9rem;
			color: #fff;
			display: flex;
			flex-direction: column;
			.text-input input {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
			.tree-title {
				padding-bottom: 1.2rem;
				padding-left: 0.6rem;
			}
			.btn {
				padding: 0.5rem 2.5rem;
			}
			button {
				color: #fff;
				&.active {
					color: $highlight-color;
				}
			}
		}
		.panels-container {
			padding: 0 1.3rem;
			flex: 1;
			gap: 1.3rem;
			display: flex;
			.gutter {
				display: flex;

				// padding: 0 1.1rem;
				flex: 1;
			}
		}

		.files-header {
			display: flex;
			align-items: center;
			.current-folder {
				color: $highlight-color;
				font-size: 1.4rem;
				margin-left: 1rem;
				padding-top: 0.5rem;
			}
			.icon {
				margin-left: 1.7rem;
			}
			.actions {
				margin-right: 1.1rem;
				margin-left: 0.5rem;
			}
			.main-btn {
				margin-left: auto;
				button {
					line-height: 1;
					padding-left: 3.7rem;
					padding-right: 3.7rem;
				}
			}
		}

		.members-header {
			display: flex;
			align-items: center;
			.title {
				color: #000;
				font-size: 1.4rem;
				margin-left: 1rem;
				padding-top: 0.5rem;
			}

			.main-btn {
				margin-left: auto;
				button {
					line-height: 1;
					padding-left: 2.7rem;
					padding-right: 2.7rem;
					img {
						vertical-align: initial;
					}
				}
			}
		}
	}
}
