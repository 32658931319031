.test-button {
	padding: 20px;
	background-color: blue;
	color: inherit;
	font-size: 20px;
	border: none;
	cursor: pointer;
}
.genie-modal {
	.modal-veil-x {
		height: 100vh;
		width: 100%;
		background-color: #000;
		opacity: 0;
		visibility: hidden;
		position: fixed;
		z-index: 495;
		top: 0;
		left: 0;
		display: none;
		&.show {
			display: block;
			//align-items: center;
			//justify-content: center;
		}
	}
	.modal-container-x {
		height: 100vh;
		width: 100%;
		position: fixed;
		z-index: 500;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 500;
		background-color: transparent;
		// pointer-events: none;

		&.show {
			display: flex;
			//align-items: center;
			//justify-content: center;
		}

		.modal-dialog-x {
			position: absolute;
			//width: 73rem;

			overflow: hidden;
			opacity: 0;
			visibility: hidden;

			z-index: 497;

			.modal-content-container {
				color: black;
				text-align: left;
				background-color: #fff;
				border-radius: 0.3rem;
				padding: 1rem;
				margin-bottom: 3rem;
				width: 69rem;
				box-shadow: 0 1px 5px rgba(0, 0, 0, 0.41);
			}
		}
	}
}
