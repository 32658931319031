.confirmation-modal {
	max-width: 56.5rem;
	.modal-body {
		background-image: image("other/POPUPWAVE100.png");
		background-repeat: no-repeat;

		background-position: bottom right;
	}

	.confirmation-modal-container {
		padding-bottom: 4.9rem;

		.close-conainer {
			text-align: right;
			button {
				padding-right: 1.5rem;
				padding-top: 1rem;
			}
		}
		.confirmation-modal-body {
			padding: 0 5.9rem;
			padding-top: 1.4rem;
			min-height: 11.8rem;
			.title {
				font-size: 1.6rem;
				text-align: center;
			}
			.buttons {
				text-align: center;
				padding-top: 5.6rem;
				.btn {
					width: 18.4rem;
				}
			}
			.body {
				margin-top: 2rem;
				text-align: center;
				font-size: 1.4rem;
			}
		}
	}
}
