.edit-member-modal {
	max-width: 156.5rem;
	.edit-room-member-container {
		padding-bottom: 1.9rem;

		.title {
			font-size: 2.2rem;
			color: $primary-color;
			position: relative;

			display: flex;
			align-items: center;
			margin-bottom: 2.1rem;
			img {
				margin-right: 1.3rem;
			}
		}

		.buttons {
			text-align: center;
			padding-top: 6.6rem;
			padding-bottom: 1rem;
			.btn {
				width: 14.4rem;
			}
		}
		.sections-container {
			display: flex;
			margin-top: 3.5rem;
			position: relative;
			.edit-togle {
				position: absolute;
				right: 1rem;
				top: -2rem;
				img {
					height: 2.3rem;
				}
			}
		}

		.settings {
			flex: 1;

			& > div {
				padding-right: 5.4rem;
				padding-left: 3.5rem;
			}
		}
		.access {
			flex: 1;

			border-left: 1px solid $gray2;

			& > div {
				padding-left: 5.4rem;
			}
			.sett-row {
				display: flex;
				margin-bottom: 2.7rem;
				color: #000;

				font-size: 1.4rem;
				align-items: flex-start;
				margin-left: -0.9rem;
				margin-right: -0.9rem;
				.sett-col {
					flex: 1;
					padding: 0 0.9rem;
					max-width: 33.33%;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
				}
			}
			.access-description {
				padding-right: 0.5rem;
				margin-top: 1.6rem;
				width: 51rem;
				p {
					position: relative;
					padding-left: 3rem;
					font-size: 1.2rem;
					color: #565656;
					margin-bottom: 0rem;
					padding-bottom: 0.8rem;

					img {
						position: absolute;
						max-width: 1.7rem;
						left: 0;
						top: 0rem;
					}
				}
			}

			.all-permissions-container {
				padding-right: 4rem;
				table {
					//width: 100%;
					border-top: 1px solid #cecece;
					border-bottom: 1px solid #cecece;
					table-layout: fixed;
					td {
						border-left: 1px solid #dadada;
						border-right: 1px solid #dadada;
						border-top: 1px solid #dadada;
						padding: 1rem 0.5rem;
						width: 14rem;
						&:first-child {
							border-left: none;
						}
						&:last-child {
							border-right: none;
						}
						.react-select__control {
							border: none !important;
						}

						.permissions-view {
							font-size: 1.2rem;
							padding-left: 2.5rem;
							color: #565656;
						}
					}
					th {
						border-left: 1px solid #dadada;
						border-right: 1px solid #dadada;
						padding: 1.4rem 1.4rem;
						text-align: center;
						color: #000;
						font-size: 1.4rem;
						line-height: 1.2;

						font-weight: normal;
						height: 5.7rem;
						&:first-child {
							border-left: none;
						}
						&:last-child {
							border-right: none;
						}
					}
				}
			}
		}
	}
}
