.timeline {
	position: relative;

	&-line {
		height: 0.1rem;
		background-color: $primary-color;
		position: absolute;
		bottom: 0.4rem;
		width: 100%;
		z-index: 1;
		&:after {
			content: "";
			position: absolute;
			background-image: image("icons/chevron-left.svg");
			@include size(0.64rem, 1.1rem);
			z-index: 3;
			top: -0.5rem;
			left: -0.6rem;
			background-size: contain;
			background-position: center;
		}
		&:before {
			content: "";
			position: absolute;
			background-image: image("icons/chevron-right.svg");
			@include size(0.64rem, 1.1rem);
			z-index: 3;
			top: -0.5rem;
			right: -0.6rem;
			background-size: contain;
			background-position: center;
		}
	}
	&-items {
		display: flex;
		justify-content: space-around;
		position: relative;
		z-index: 2;
		&-item {
			height: 6.7rem;
			width: 3.9rem;
			background-size: contain;
			background-repeat: no-repeat;
			position: relative;
			cursor: pointer;
			&.type-1 {
				background-image: image("icons/timeline-flag.svg");
			}
			&.type-2 {
				background-image: image("icons/timeline-op.svg");
			}
			&.type-3 {
				background-image: image("icons/timeline-split.svg");
			}
			&.type-4 {
				background-image: image("icons/timeline-sell.svg");
			}

			&-date {
				white-space: nowrap;
				position: absolute;
				bottom: -2rem;
				font-size: 1.2rem;

				margin-left: 0.5rem;
				left: -50%;
			}
		}
	}
}

.timeline-event-info-popover {
	background-color: #f4f4f4;
	margin-bottom: 1rem;
	box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.2);
	border: none;
	.timeline-event-info {
		padding: 1.4rem;
		.info-line {
			display: flex;
			margin-bottom: 0.8rem;
			font-size: 1.1rem;
			.info-label {
				padding-right: 1rem;
			}
		}

		.seperator {
			border-bottom: 1px solid #9c9c9c;
			margin-bottom: 1rem;
			margin-top: 1rem;
			width: 100%;
		}
		.eventTitle {
			border-bottom: 1px solid #9c9c9c;
			margin-bottom: 0.5rem;
		}
	}
}
