.full-screen-container {
	transition: width 500ms ease-in-out, height 500ms ease-in-out;
	z-index: 1000;
	&.active {
		padding: 1.5rem;
		position: fixed;
		flex-direction: column;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		background-color: #fff;

		@include animation("fadeIn 1s");
		overflow: auto;
	}

	&.inactive {
		@include animation("fadeIn2 1s");
	}

	@include keyframes("fadeIn") {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@include keyframes("fadeIn2") {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	// @keyframes fadeIn2 {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-moz-keyframes fadeIn2 {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-webkit-keyframes fadeIn2 {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-o-keyframes fadeIn2 {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-ms-keyframes fadeIn2 {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @keyframes fadeIn {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-moz-keyframes fadeIn {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-webkit-keyframes fadeIn {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-o-keyframes fadeIn {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }

	//   @-ms-keyframes fadeIn {
	//     0% {opacity:0;}
	//     100% {opacity:1;}
	//   }
}

.full-screen-enter {
	opacity: 0;
}
.full-screen-exit {
	opacity: 1;
}
.full-screen-enter-active {
	opacity: 1;
}
.full-screen-exit-active {
	opacity: 0;
}
.full-screen-enter-active,
.full-screen-exit-active {
	transition: all 500ms ease-in-out;
}
