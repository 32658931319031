.edit-user-container {
	.close-conainer {
		text-align: right;
		button {
			padding-right: 1.5rem;
			padding-top: 1rem;
		}
	}
	.tabs {
		border-bottom: 1px solid $primary-color;
		padding-left: 3rem;
		.tabs-container {
			display: flex;
			.tab {
				display: block;
				padding: 0.9rem 4.3rem;
				background-color: #c8c8c8;
				color: #fff;
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
				width: 17.2rem;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
				margin-right: 0.16rem;

				font-size: 1.4rem;
				text-decoration: none;
				transition: background-color 300ms ease-in-out;
				position: relative;
				cursor: pointer;
				&:hover {
					background-color: #7a7c88;
				}
				&.active {
					background-color: $primary-color;
				}
			}
		}

		.create-new-room-btn {
			margin-left: auto;
			margin-top: -1rem;
			button {
				font-size: 1.4rem;
				align-items: center;
				display: flex;
				color: #fff;
				&::after {
					margin-left: 3rem;
				}
			}
		}
	}
	.main-title {
		font-size: 2.2rem;
		color: $primary-color;
		position: relative;
		padding-left: 2.6rem;
		// margin-top: 4rem;
		&::before {
			content: "";
			position: absolute;
			left: -1rem;
			top: 50%;
			margin-top: -1.2rem;
			height: 2.3rem;
			width: 2.1rem;
			background-color: $primary-color;
		}
	}
}
