.usersAndPermissions {
	$border-thin-color: #cecece;
	animation-duration: 0.5s;
	table {
		table-layout: fixed;
		width: 100%;
	}

	.info-container {
		display: flex;
		font-size: 1.4rem;

		justify-content: center;
		.info {
			width: 130.4rem;
		}
		.date-container {
			text-align: center;
			display: flex;
			flex: 1;
			// justify-content: center;
			.app-date-picker {
				margin-left: 3rem;
				margin-right: 2rem;
				input {
					border: 1px solid #d7ddf1;
					border-radius: 0.3rem;
					width: 15.6rem;
				}
			}
		}
	}

	.table-container {
		background-color: #fff;
		padding-left: 3.2rem;
		padding-right: 3.2rem;
		position: relative;
		padding-top: 2.7rem;
		padding-bottom: 3.4rem;
		border-radius: 0.5rem;
		box-shadow: 0px 0.1rem 0.1rem #00000026;
		border: 0.1rem solid #f1f1f1;

		@include lt-md {
			padding: 1.5rem;
		}

		.btn-newUser {
			margin-right: 1.9rem;
			width: 17rem;
			height: 4rem;
			line-height: 1.4rem;
			font-size: 1.4rem;
			// span {
			//   font-size: 2.5rem;
			//   line-height: 2rem;
			//   vertical-align: text-top;
			// }
		}
	}

	.scrollbar-container {
		width: 180rem;
	}
	.searchFilter {
		margin-top: 5rem;
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
		.search-container {
			flex: 0 0 25rem;
		}
		@include lt-xs {
			.search-container {
				flex: 1 1 42rem;
			}
		}
	}
	.search-container {
		width: 23.4rem;
		height: 4.2rem;
		display: inline-block;
		flex: 1;
		& > span {
			position: relative;
			display: inline-block;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			&:after {
				content: "";
				position: absolute;
				background-image: image("icons/Path 766.svg");
				height: 1.65rem;
				width: 1.65rem;
				right: 1.2rem;
				top: 0.94rem;
				z-index: 11;
				background-repeat: no-repeat;
				background-size: contain;
			}
			input {
				height: 3.8rem;
				color: #565656;
			}
		}

		input {
			border: 1px solid #c3c1c1;
			padding: 0.7rem 3.3rem 0.7rem 1rem;
			line-height: 1;
			font-size: 1.2rem;
			border-radius: 1.9rem;
			width: 100%;
			&:focus {
				outline: none;
			}
		}
	}

	.card-container {
		.userCard {
			width: 22.6rem;
			flex: 0 1 22.6rem;
			height: 9.2rem;
			background: $color2 0% 0% no-repeat padding-box;
			box-shadow: 0px 4px 0.87px #00000026;
			border-radius: 17px;
			// margin-right: 10rem;
			cursor: pointer;
			display: flex;
			align-items: center;

			.icon {
				margin-left: 2rem;
			}
			.title {
				margin-right: 1.5rem;
				text-align: center;
				letter-spacing: 0.36px;
				font-size: 1.6rem;

				width: 14.4rem;
				line-height: 3rem;
				.text {
					text-align: right;
					line-height: 2.2rem;
				}
				.right {
					font-size: 3rem;
					text-align: right;
				}
			}
		}

		.TotalUsers {
			border: 0.15rem solid $color2;
			background-color: #ffffff;
			color: black;

			&.pressed {
				background-color: $color2;
				color: #ffffff;
			}
		}

		.PendingInvitations {
			border: 0.15rem solid $color4;
			background-color: #ffffff;
			color: black;
			&.pressed {
				background-color: $color4;
				color: #ffffff;
			}
		}

		.Editors {
			border: 0.15rem solid $color3;
			background-color: #ffffff;
			color: black;
			&.pressed {
				background-color: $color3;
				color: #ffffff;
			}
		}

		.Shareholders {
			border: 0.15rem solid $color1;
			background-color: #ffffff;
			color: black;

			&.pressed {
				background-color: $color1;
				color: #ffffff;
			}
		}
	}

	.dataRoomRow {
		background-color: #f9f9f9;
		width: 100%;
		height: 7.5rem;
		border-top: 0.1rem solid #cecece;
		border-bottom: 0.1rem solid #cecece;

		&.hide {
			display: none;
		}
		&.show {
			display: table-row;
		}

		.dataRoomCell {
			display: flex;
			justify-content: center;
			.cell {
				margin-left: 1rem;
				margin-right: 1rem;
				width: 15.3rem;

				.roomTitle {
					color: #000000;

					margin-bottom: 0.5rem;
				}
			}
		}
	}

	.default-grid {
		text-align: center;
		margin-left: auto;
		margin-right: auto;

		width: 100%;
		white-space: nowrap;
		overflow: auto;

		margin-top: 3rem;

		.grid-title {
			background-color: #e8eaf9;
			border-top: 0.1rem solid #cecece;
			border-bottom: 0.1rem solid #cecece;
			color: #000000;
			font-size: 1.4rem;
			text-align: center;

			padding: 1rem;
			height: 5.6rem;
			line-height: 2.1rem;
		}

		.grid-firstTitle {
			background-color: #e8eaf9;
			border-top: 0.1rem solid #cecece;
			border-bottom: 0.1rem solid #cecece;

			text-align: center;
		}

		.grid-lastTitle {
			color: #565656;

			text-align: center;
			background-color: #e8eaf9;
			border-top: 0.1rem solid #cecece;
			border-bottom: 0.1rem solid #cecece;
		}

		.grid-row--deactivate {
			background-color: #f1f1f1;
		}

		.grid-cell {
			color: #757575;
			height: 3.5rem;
			text-align: center;
			//   border: 0.1rem solid $border-thin-color;
			padding: 2rem 1rem;

			&--deactivate {
				opacity: 0.6;
			}
		}

		.grid-footer {
			color: #3e4049;
			height: 5.6rem;
			text-align: center;
			border: 0.1rem solid $border-thin-color;
			border-top: 0.15rem solid #a3a3a3;
			padding: 2rem 1rem;

			font-size: 1.4rem;
		}

		.gridName {
			width: 20rem;
			text-align: left;
			padding-left: 2.2rem;
			font-size: 1.4rem;

			img {
				margin-right: 1.27rem !important;
			}
		}

		.gridEmail {
			width: 23rem;
			text-align: left;
			padding-left: 2.2rem;
			font-size: 1.4rem;

			img {
				margin-right: 1.27rem !important;
			}
		}

		.gridCompany {
			width: 15rem;
			text-align: left;
			padding-left: 2.2rem;
			font-size: 1.4rem;

			img {
				margin-right: 1.27rem !important;
			}
		}
		.gridGeneral {
			width: 15rem;
			text-align: left;
			padding-left: 2.2rem;
			font-size: 1.4rem;

			img {
				margin-right: 1.27rem !important;
			}
		}

		.gridStatus {
			width: 8rem;
			text-align: left;
			padding-left: 2.2rem;
			color: #000000;
			font-size: 1.4rem;

			img {
				margin-right: 1.27rem !important;
			}
		}

		.gridDelete {
			width: 8rem;
			text-align: left;
			padding-left: 2.2rem;
			color: #000000;
			font-size: 1.4rem;

			img {
				margin-right: 1.27rem !important;
			}
		}

		.gridDescription {
		}

		.gridGrants {
			width: 14.2rem;
			text-align: center;
		}

		.hint {
			position: relative;
			bottom: 5px;
		}

		.gridVestingScenario {
			width: 21rem;
		}

		.gridEdit {
			width: 9.3rem;
		}
	}
}
