.checkbox {
	width: 1.8rem;
	height: 1.8rem;

	border: 1px solid #cecece;
	border-radius: 0.2rem;
	display: inline-block;
	margin-right: 0.9rem;
	vertical-align: middle;
	position: relative;
	cursor: pointer;
	box-sizing: content-box;
	&.yellow-check {
		background-color: #fff;
		&:hover {
			background-color: #f1f1f2 !important;
		}
		&:after {
			background-image: image("icons/Group 733.svg");
		}
	}
	&.white-check {
		&:hover {
			background-color: #f1f1f2 !important;
		}
		&.checked {
			background-color: $primary-color;
			&:hover {
				background-color: #8f8f8f !important;
			}
		}
		&:after {
			background-image: image("icons/Path 1544.svg");
		}
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 1.2rem;
		height: 0.9rem;
		background-size: contain;
		background-repeat: no-repeat;
		top: 0.5rem;
		left: 0.3rem;
		transition: all 250ms ease-in-out;
		opacity: 0;
		background-position: center;
	}
	&.checked:after {
		opacity: 1;
	}

	&.disabled {
		cursor: default;
	}
}
