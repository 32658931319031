.signatures-modal {
	max-width: 73rem;
	font-size: 1.4rem;
	.file-name {
		background-color: #f4f4f4;
		margin-right: -1rem;
		margin-left: -1rem;
		text-align: center;
		font-size: 1.4rem;

		padding: 1rem;
		margin-top: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.param-buttons-container {
		> div {
			display: flex;
			align-items: center;
			margin-top: 15px;
		}
	}
	.settings-container {
		display: flex;
		color: #000;
		padding: 3rem 1.9rem;
		border-bottom: 1px solid #e1e1e1;
		margin: 0 2rem;
		> div {
			display: flex;
			align-items: center;
			margin-right: 5.3rem;
		}
	}
	.add-signer-conatiner {
		margin: 2rem 2rem;
		padding: 0rem 1.9rem;
		button {
			min-width: 8rem;
		}
	}
	.sett-row {
		display: flex;
		margin-bottom: 1.5rem;
		color: #000;

		font-size: 1.4rem;
		align-items: flex-start;
		margin-left: -0.9rem;
		margin-right: -0.9rem;
		.sett-col {
			flex: 1;
			padding: 0 0.9rem;
			max-width: 33.33%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		}

		.form-input-group {
			label {
				font-size: 1.3rem;
				color: #000;
				position: relative;
			}
			&.required {
				label:after {
					font-size: 1.6rem;
					color: #000;
					content: "*";
					position: absolute;
					right: -1rem;
					top: -0.3rem;
				}
			}
		}
	}

	.signers-container {
		margin: 2rem 2rem;
		padding: 3rem 1.7rem;

		table {
			width: 100%;
			table-layout: fixed;
			th {
				color: #000;
			}
			td,
			th {
				border-bottom: 1px solid #e1e1e1;
				padding: 1.2rem 0.5rem;
			}
			.name {
				width: 20rem;
			}
			.controls {
				width: 9rem;
			}
		}
	}
}
