.file-versions-modal {
	max-width: 73rem;

	.file-name {
		background-color: #f4f4f4;
		margin-right: -1rem;
		margin-left: -1rem;
		text-align: center;
		font-size: 1.4rem;

		padding: 1rem;
		margin-top: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.file-versions {
		padding: 0 2rem;
		.dt-row > div {
			padding: 1.6rem 0.5rem !important;
		}
		.dt-header-row {
			padding-bottom: 0;
			padding-top: 0;
			& > div {
				padding-bottom: 1.2rem !important;
			}
		}
		.check-col {
			flex-basis: 2.5rem;
			text-align: center;
		}
		.icon-col {
			flex-basis: 3rem;
			text-align: center;
			img {
				height: 2.5rem;
			}
		}
		.name-col {
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.date-col {
			flex-basis: 11rem;
			text-align: center;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		.time-col {
			flex-basis: 11rem;
			text-align: center;
		}
		.user-col {
			flex-basis: 12rem;
		}
		.delete-col {
			flex-basis: 4rem;
			text-align: center;
		}
	}

	.upload-version-container {
		padding: 5.6rem 6.6rem 0 6.6rem;
		.u-title {
			font-size: 1.4rem;
			margin-bottom: 1.3rem;
			color: #000;
		}
	}
}
