.chartContainer {
    display: flex;
    flex-direction: column;
    /* width: 60%; */
    /* min-width: 20rem; */
    /* max-height: 20rem; */
    /* overflow: scroll; */
}

.chartItem {
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* width: 20rem; */

    & p {
        align-items: start;
        justify-content: flex-start;
        min-width: 43%;
        margin: 0;
        padding: 1rem 0 0 4rem;
    }
}

.divider {
    border-left: 2px solid #E8EAF5;
    margin-left: 2rem;
    margin-top: 1rem;
    /* height: 5rem; */
}

.chartColumns {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    /* background-color: aqua; */
}

.singleColumn {
    display: flex;
    flex-direction: column;
}

.legendBlock {
    justify-content: space-between;
    min-width: 28rem;
}

.blockRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2rem;

    .rowData {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-width: 22rem;
        padding-right: 1rem;

        p {
            margin: 0;
            padding: 0 0 0 1rem;
        }
    }
}

.legendBlock:first-child {
    padding-right: 4rem;
    border-right: 1px solid #E8EAF5;
}