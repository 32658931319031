.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        padding: 1rem 0;
        font-size: 2.4rem;
    }
}

.buttons {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5rem;
}