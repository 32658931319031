.cardContainer {
    /* background-color: blueviolet; */
    padding: 6rem;

}

.tableHeader {
    color: #4d4d4d;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 2rem 0;
}


.beneficiary {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 2rem;
}

.icon {
    width: 5.4rem;
    margin-right: 3rem;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin: 2rem;
}

.row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    width: 100%;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #D0D4E4;
    border-radius: 20px;
    margin-bottom: 4rem;
    overflow-y: hidden;
    overflow-x: auto;
}