.file-uploader {
	.file-uploader-container {
		// border:1px dashed #C8C8C8;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C8C8C8FF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
		text-align: center;
		padding: 0.7rem 0 1rem 0;
		flex: 1;
		.instructions {
			margin-top: 1.5rem;
			font-size: 1.2rem;
			color: #757575;
			label {
				color: #757575;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		&.inlineContainer {
			flex: none;
		}
	}

	.inlineContainer {
		display: inline-block;
		vertical-align: middle;
	}

	.file-drop-target.file-drop-dragging-over-frame {
		.file-uploader-container {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	.files-list {
		.noBorder {
			border-top: none !important;
			border: none !important;
			border-bottom: none !important;
		}
		padding: 0 2rem;
		.dt-row > div {
			padding: 1.6rem 0.5rem !important;
		}
		.dt-header-row {
			padding-bottom: 0;
			padding-top: 0;
			& > div {
				padding-bottom: 1.2rem !important;
			}
		}

		.icon-col {
			flex-basis: 3rem;
			text-align: center;
			img {
				height: 2.5rem;
			}
		}
		.name-col {
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 50rem;
		}

		.status-col {
			flex-basis: 21.6rem;
		}
		.delete-col {
			flex-basis: 4rem;
			text-align: center;
		}

		.invalid {
			color: #d67878;
		}
	}
}
