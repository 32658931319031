.excel-import {
	max-width: 85vw;

	overflow: hidden;
	.main-container {
		display: flex;
		flex-direction: column;
		height: 80vh;
		margin: 0 10rem;
		.sub-title-container {
			margin: 5rem 0rem;
			margin-bottom: 0rem;
			.sub-title {
				color: $primary;
				font-size: 2.2rem;
			}
			.sub-title-2 {
				font-size: 1.6rem;
			}
			.data-type-container {
				width: 40rem;
				margin-top: 1rem;
			}
		}
	}
	.errors-container {
		display: flex;
		justify-content: flex-end;
		height: 3rem;

		margin-bottom: 1rem;
		align-items: center;
	}
	.data-table-container {
		overflow: hidden;

		flex-direction: column;
		display: flex;
		border: 1px solid $border-color;
	}
	.data-table-border {
		border: 1px solid $border-color;
		// border-top: 2px solid $border-color;
		//display: inline-block;
		//margin-bottom: 2.5rem;
	}
	.data-table-x {
		table-layout: fixed;

		border-collapse: collapse;

		td,
		th {
			border-bottom: 1px solid $border-color;
			border-left: 1px solid $border-color;
			&:first-child {
				border-left: none;
			}
			position: relative;
			&.hovering {
				background-color: #e1e1e1;
			}
			&.selected {
				background-color: #d1d6f6;
			}
		}
		tr:last-child {
			td {
				border-bottom: none;
			}
		}
		th {
			background-color: #a8aab7;
			height: 5rem;
			.react-select__control {
				background-color: #a8aab7 !important;
				border: none !important;
				.react-select__single-value,
				.react-select__placeholder {
					color: #fff !important;
					font-weight: normal;
				}
				.react-select__dropdown-indicator {
					color: #fff !important;
				}
			}
		}
		.cell {
			width: 25rem;
			height: 4rem;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.cell-value-container {
				position: relative;
				flex: 1;
				align-items: center;
				&.is-editing {
					background-color: #f4f4f4;
				}
				.error-tip {
					position: absolute;
					right: 1rem;
					top: 0.6rem;
				}
				.cell-value {
					padding: 0.5rem 1.1rem;
					height: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.editable {
						cursor: text;
					}
					&:not(.editable) {
						cursor: default;
					}
				}
				input,
				.react-select__control {
					border: 0 !important;
					background-color: #f4f4f4;
				}
			}
		}
	}

	.buttons {
		text-align: center;
		padding-top: 4.6rem;
		margin-top: auto;
		.btn {
			min-width: 14.4rem;
		}
	}
}

.fields-mandatory-container {
	padding-bottom: 2rem;

	.fields-mandatory-title {
		display: flex;
		align-items: baseline;
		padding-bottom: 1.1rem;

		.fields-mandatory-left-icon {
			width: 2.1rem;
		}
		.fields-mandatory-sub-title {
			padding-left: 1.1rem;
			font-weight: bold;
			color: #d07b79;
			font-size: 1.1rem;
		}
	}
	.list-container {
		max-width: fit-content;
		ul {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			font-size: 1.2rem;
			margin-left: 0.8rem;
			li {
				margin-right: 3rem;
			}
		}
	}
}

.excel-import-intro {
	max-width: 73rem;
	.general-modal-container {
		padding-bottom: 0;
	}
	.main-container {
		padding: 1rem 3rem;
	}
	.sub-title-container {
		font-size: 1.2rem;
		margin-bottom: 2rem;
		.sub-title {
			font-size: 1.6rem;
		}
	}
	.data-ready {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		.action-buttons {
			width: 30rem;
			margin-top: 5rem;
			.btn {
				width: 100%;
				font-size: 1.6rem;

				margin-bottom: 2rem;
				font-weight: normal;
			}
		}
	}
	.contact-us {
		font-size: 1.2rem;
		text-align: right;
		margin-top: 5rem;
	}
	.data-types-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 8rem;

		& > div {
			padding: 2rem 1rem;
			.btn {
				width: 17rem;
				font-size: 1.4rem;

				line-height: 1;
				font-weight: normal;
				height: 4rem;
				padding: 0;

				// margin-bottom: 3rem;
				// &+.btn{
				//     margin-left: 3rem;
				// }
				//white-space: nowrap;
			}
		}
	}
}

.import-result {
	.main-container {
		padding: 1rem 3rem;
		padding-bottom: 3rem;
		text-align: center;
	}
	.x-title {
		font-size: 2.2rem;
	}

	.message {
		font-size: 1.6rem;

		margin-top: 3.5rem;
		img {
			margin-bottom: 2.5rem;
		}
	}
}
