.user-details-form {
	.title {
		font-size: 2.2rem;
		color: $primary-color;
		position: relative;

		display: flex;
		align-items: center;
		margin-bottom: 2.1rem;
		img {
			margin-right: 1.3rem;
		}
	}

	.pesron-legal-switch-container {
		margin-bottom: 3rem;
	}

	.form-input-group {
		label {
			font-size: 1.4rem;
			color: #000;
			position: relative;
		}
		&.required {
			label:after {
				font-size: 1.6rem;
				color: #000;
				content: "*";
				position: absolute;
				right: -1rem;
				top: -0.3rem;
			}
		}
	}

	.mobile-container {
		display: flex;
		& > input {
			width: 12rem !important;
		}
		.dialing-code {
			width: 8.3rem;
			margin-right: 0.9rem;
		}
	}
	.sett-row {
		display: flex;
		margin-bottom: 2.7rem;
		color: #000;

		font-size: 1.4rem;
		align-items: flex-start;
		margin-left: -0.9rem;
		margin-right: -0.9rem;
		.sett-col {
			flex: 1;
			padding: 0 0.9rem;
			max-width: 33.33%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	.view-photo-container {
		text-align: center;
		display: flex;
		width: 100%;
		justify-content: center;
		flex-direction: column;
		.btn-view-photo span {
			text-decoration: underline;
			margin-left: 1rem;
			color: #000;
		}
		img.img-preview {
			width: 100%;
			margin-top: 1rem;
			border-radius: 0.75rem;
			max-height: 15rem;
			object-fit: cover;
		}
	}
	.upload-id-container {
		text-align: center;
		margin-top: 0.7rem;
		button span {
			text-decoration: underline;
			margin-left: 1rem;
			color: #c8c8c8;
		}
	}
}
