.modal-success-container {
	.modal-content {
		min-height: 32rem;
		background-image: image("other/Group 4270.svg");
		background-repeat: no-repeat;
		background-position-x: 160%;
		background-position-y: 225%;
		background-size: 81%;
		margin: 5rem;
		min-width: 44rem;
		border: 1px solid #bbbbfa;
	}
}

.modal-error-container {
	.modal-content {
		min-height: 20rem;
		background-image: image("other/Group 2474.svg");
		background-repeat: no-repeat;
		background-position-x: 100%;
		background-position-y: 100%;
		background-size: 73%;
		margin: 5rem;
	}
}

.modal-error-container,
.modal-success-container {
	.modal-body {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		div {
			text-align: center;
		}
		.title {
			font-family: allroundgothic-bold;
			font-size: 3rem;
			color: #9999ff;
			padding-bottom: 2rem;
		}
		.message {
			font-family: $font-assistant;
			font-size: 1.75rem;
			font-weight: 600;
		}
		.sub-message {
			font-family: $font-assistant;
			font-size: 1.75rem;
			font-weight: 500;
		}
		.close-message {
			position: absolute;
			top: 10px;
			right: 15px;
		}
	}
}
