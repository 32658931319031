.sort-indicator-container {
	display: flex;
	align-items: center;
	padding: 0;
	.sort-indicator {
		margin-right: 0.5rem;
		img {
			height: 1.1rem;
		}
	}
}
