.dashboardContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
}

/* .toggleContainer {
    border: 2px solid aqua;
    height: 30rem;

    max-height: 80%;
} */

/* .gridContainer {
    background-color: aqua;
    height: 30rem;
} */

.grid {
    display: grid;
    padding: 1.6rem 3.8rem;
    background: #E8EAF5;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2.4rem;
    max-height: 32rem;
}

.card1 {
    grid-area: 1 / 1 / 3 / 6;
    min-width: 45rem;
    box-shadow: 0.3rem 0.3rem 0.3rem 0px #C9CAD066;
}

.pieCard {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pieCardPie {
    display: flex;
    flex-direction: row;
    margin-top: 1.4rem;
    width: 100%;
}

.card2 {
    grid-area: 1 / 6 / 2 / 9;
    display: flex;
    box-shadow: 0.3rem 0.3rem 0.3rem 0px #C9CAD066;
}

.card3 {
    grid-area: 2 / 6 / 3 / 9;
    box-shadow: 0.3rem 0.3rem 0.3rem 0px #C9CAD066;
}

.card4 {
    grid-area: 1 / 9 / 3 / 13;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 2.6rem 0;
    margin: 0;
    overflow: hidden;
}

.chartLegend {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 4rem;
}

.legendHeader {
    display: flex;
    flex-direction: row;

    & h3 {
        font-weight: 700;
        font-size: 2rem;
        margin-right: 1rem;
    }
}

.chartLegendLower {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 1.7rem;

}

.cardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1.4rem;
    font-size: 1.7rem;

    & h3 {
        font-weight: 700;
        font-size: 1.5rem;
    }

    & p {
        width: 100%;
    }
}

.mainTable {
    padding: 4rem;
}

.vestCard {
    background: #ffffff;
    border-radius: 16;
    overflow: hidden;
}

.vestTable {
    width: 100%;
    height: 100%;
    display: flex;
    /* gap: 3.2rem; */
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    & h3 {
        color: black;
        font-size: 1.6rem;
        margin-left: 1.6rem;
        margin-bottom: 0;
    }

    & td,
    th {
        border-radius: 0 !important;
    }

    & table {
        border-radius: 0 !important;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    & div {
        padding: 1rem 0;
        margin: 0;
    }
}