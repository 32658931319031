.file-activity-modal {
	max-width: 73rem;

	.file-name {
		background-color: #f4f4f4;
		margin-right: -1rem;
		margin-left: -1rem;
		text-align: center;
		font-size: 1.4rem;

		padding: 1rem;
		margin-top: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.file-activity {
		padding: 0 2rem;
		.dt-header-row {
			padding-bottom: 0;
			padding-top: 0;
			& > div {
				padding-bottom: 1.2rem !important;
			}
		}
		.dt-row > div {
			padding: 1.6rem 0.5rem !important;
		}
		.action-col {
			flex: 1;
		}
		.date-col {
			flex-basis: 11rem;

			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		.time-col {
			flex-basis: 9rem;
		}
		.user-col {
			flex-basis: 15rem;
		}
	}
}
