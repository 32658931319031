.drawerContainer {
    border-bottom: 2px solid #ececec;
    padding-top: 4rem;
    max-width: 100%;

    & h3 {
        color: #4d4d4d;
        font-size: 1.8rem;
    }
}

.drawerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    max-width: fit-content;
    min-width: 30rem;
}

.drawerTable {
    width: 100%;
    /* background-color: aqua; */
    margin: 0;
}

.lightFont {
    color: #BAC0D9;
}