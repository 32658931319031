// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
	#{$context} & {
		@content;
	}
}

@mixin form-line($flex, $padding-horizontal) {
	.form-line {
		display: flex;
		margin-bottom: 2.7rem;
		color: #000;

		font-size: 1.4rem;
		align-items: stretch !important;

		&.has-sub-text {
			margin-bottom: 0.8rem;
		}
		.form-cell {
			flex: $flex;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			.form-cell-children {
				padding: 0 $padding-horizontal;
			}
			&:last-child {
				.form-cell-children {
					padding-right: 0;
				}
			}
			&:first-child {
				.form-cell-children {
					padding-left: 0;
				}
			}
			&:only-child {
				.form-cell-children {
					padding-right: $padding-horizontal;
				}
			}
		}

		.form-input-group {
			.app-date-picker {
				width: 100%;
				input {
					width: 100% !important;
				}
			}

			label {
				font-size: 1.4rem;
				color: #000;
				position: relative;
				padding-left: 0.5rem;
			}
			&.required {
				label:after {
					font-size: 1.6rem;
					color: #000;
					content: "*";
					position: absolute;
					right: -1rem;
					top: -0.3rem;
				}
			}
			.sub {
				color: #757575;
				font-size: 1.2rem;
				text-align: right;
				padding-right: 0.5rem;
			}
		}
	}
}
@mixin animation($anim) {
	animation: #{$anim};
	-webkit-animation: #{$anim};
	-moz-animation: #{$anim};
	-o-animation: #{$anim};
	-ms-animation: #{$anim};
}
@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}
