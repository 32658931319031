.signatures-status-modal {
	max-width: 53.4rem;
	font-size: 1.4rem;

	& .title {
		&::before {
			left: -1px;
		}
	}

	& .modal-body {
		padding: 0;
	}

	.file-name-container {
		background-color: #f4f4f4;
		margin-right: -1rem;
		margin-left: -1rem;
		text-align: center;
		font-size: 1.4rem;

		padding: 1rem;
		margin-top: 2rem;
		display: flex;
		// align-items: center;
		justify-content: center;
		.file-name {
			align-self: center;
			flex: 1;
			padding-left: 7.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.signers-head {
		font-size: 1.4rem;
		color: #757575;
		margin-left: auto;
		.count {
			margin-right: 1.5rem;
		}
		img {
			height: 1.8rem;
		}
	}
	.signers-list {
		padding: 2.4rem 3.5rem;
		padding-bottom: 0;
		.signers-line {
			display: flex;
			margin-bottom: 0.5rem;
			height: 3.5rem;

			color: #000;
			.name {
				width: 18rem;
			}
			.status {
				margin-left: auto;
			}
			.file-signed {
				flex: 1;
				padding-left: 8rem;
				.doc {
					display: flex;
					margin-bottom: 1rem;

					.file-name {
						flex: 1;
						.file-icon img {
							width: 1.6rem;
						}
					}
				}
			}
		}
	}

	.document-items {
		padding: 0rem 3.5rem;
		padding-top: 3.2rem;
		padding-bottom: 2.1rem;
		border-bottom: 1px solid #f4f4f4;
		.doc {
			display: flex;
			margin-bottom: 1rem;

			.file-name {
				flex: 1;
				.file-icon img {
					width: 1.6rem;
					margin-right: 1rem;
				}
			}
		}
	}

	.DocumentItem {
		display: flex;
		margin-bottom: 1rem;
		align-items: center;
		justify-content: space-between;
	}

	.DocumentItem__listing {
		display: flex;
		align-items: center;
	}

	.DocumentItem__listing-menu {
		margin-left: 2rem;
	}

	.DocumentItem__listing-icon {
		& img {
			width: 1.6rem;
			margin-right: 1rem;
		}
	}

	.add-signer-conatiner {
		margin-bottom: 2rem;
		button {
			span {
				color: #000;
				text-decoration: underline;
				&:hover {
					color: $highlight-color;
				}
			}
		}
	}
}
