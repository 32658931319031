.text-input {
	#{$all-text-inputs} {
		border: 1px solid $gray2;
		border-radius: 0.3rem;
		width: 100%;
		padding: 1rem 1.4rem;
		&:focus,
		&:focus-visible {
			outline: none !important;
			border: 1px solid #495066 !important;
		}
	}

	&.has-error {
		#{$all-text-inputs} {
			position: relative;
			border-color: #f35669 !important;
		}
	}

	textarea {
		min-height: 7.7rem;
	}
}
