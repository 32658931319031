.optionsScreen {
	$border-thin-color: #cecece;
	table {
		table-layout: fixed;
	}

	// .info-container {
	//     display: flex;
	//     font-size: 1.4rem;
	//
	//     .info {
	//         width: 74.6rem;
	//     }
	.date-container {
		// position: absolute;
		// left: 0;
		// right: 0;
		// bottom: 1.5rem;
		// width: 42rem;
		// margin-left: auto;
		// margin-right: auto;
		// z-index: 100;
		// display: flex;
		/* margin-left: auto; */
		/* margin-right: auto; */
		color: black;
		z-index: 5;
		@include lt-xs {
			position: absolute;
			bottom: calc(-100% - 2rem);
			left: 0;
		}
		@include gt-xs {
			transform: translate(50%, -25%);
		}
		// justify-content: center;
		.app-date-picker {
			margin-left: 1.1rem;
			margin-right: 2rem;
			box-shadow: 0 0 4px 3px rgba(62, 64, 73, 0.09);
		}

		input {
			border: 2px solid $primary-color !important;
		}
	}
	//}
	.table-container {
		background-color: #fff;
		padding-left: 3.2rem;
		padding-right: 3.2rem;
		position: relative;
		padding-top: 2.7rem;
		padding-bottom: 3.4rem;
		border-radius: 0.5rem;
		box-shadow: 0px 0.1rem 0.1rem #00000026;
		border: 0.1rem solid #f1f1f1;
	}
}
