.cellContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.cellActions {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
    padding-left: 1rem;
}

.benName {
    cursor: pointer
}

.cellContainer:hover .cellActions {
    opacity: 1;
    visibility: visible
}