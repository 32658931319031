.filters {
	position: relative;

	margin-top: 3rem;
	display: flex;

	.filter {
		margin-right: 0.7rem;
	}

	.app-date-picker {
		width: 100%;
		input {
			border: 1px solid #d7ddf1;
			border-radius: 0.3rem;
			width: 100% !important;
		}
	}

	.form-input-group {
		label {
			font-size: 1.4rem;
			color: #000;
			position: relative;
			padding-left: 0.5rem;
		}
		&.required {
			label:after {
				font-size: 1.6rem;
				color: #000;
				content: "*";
				position: absolute;
				right: -1rem;
				top: -0.3rem;
			}
		}
		.sub {
			color: #757575;
			font-size: 1.2rem;
			text-align: right;
			padding-right: 0.5rem;
		}
	}

	.form-line {
		display: flex;
		margin-bottom: 2.7rem;
		color: #000;

		font-size: 1.4rem;
		align-items: stretch !important;

		&.has-sub-text {
			margin-bottom: 0.8rem;
		}
		.form-cell {
			min-width: 14.9rem;
			width: 14.9rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			&.small {
				min-width: 8.2rem !important;
				width: 8.2rem !important;
			}
			.form-cell-children {
				margin-right: 1rem;
			}
			&:last-child {
				.form-cell-children {
					padding-right: 0;
				}
			}
			&:first-child {
				.form-cell-children {
					padding-left: 0;
				}
			}
			&:only-child {
				.form-cell-children {
					padding-right: 2.2rem;
				}
			}
		}
	}

	.btn-apply {
		width: 7.8rem;
		height: 2.97rem;
		font-size: 1.4rem;
		line-height: 1.4rem;
		margin-left: 1.5rem;
	}

	.export-btn-container {
		display: inline-block;
		border-left: 1px solid #000;
		margin-left: 2rem;
	}
}
