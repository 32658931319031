.modal-title-container {
	.modal-title {
		display: flex;

		.title-text {
			font-size: 2.2rem;
			color: $primary-color;
			position: relative;
			padding-left: 2.6rem;

			&::before {
				content: "";
				position: absolute;
				left: -1rem;
				top: 50%;
				margin-top: -1.2rem;
				height: 2.3rem;
				width: 2.1rem;
				background-color: $primary-color;
			}
		}
	}

	.close-conainer {
		text-align: right;
		button {
			padding-right: 1.5rem;
			padding-top: 1rem;
		}
	}
}
