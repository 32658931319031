.pesron-legal-switch-container {
	display: inline-flex;
	//justify-content: center;

	//margin-top: 2.7rem;

	.switch {
		// width: 25rem;
		height: 2.973rem;
		border-radius: 1.9rem;
		border: 0.1rem solid #9999ff;
		display: flex;
		align-items: center;
		overflow: hidden;
		width: 100%;
		.sw-option {
			min-width: 94px;
			height: 2.973rem;
			flex: 1;
			padding-top: 0.4rem;
			padding-bottom: 0.4rem;
			display: flex;
			align-items: center;
			color: #c8c8c8;
			cursor: pointer;
			white-space: nowrap;
			border-radius: 1.9rem;
			transition: all 250ms ease-in-out;
			.sw-title {
				display: inline-block;
			}
			.icon {
				display: inline-block;
				width: 2.364rem;
				height: 2.364rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
			&:nth-child(1) {
				padding-left: 2.3rem;
				margin-right: 0.5rem;
				padding-right: 1.5rem;
				.sw-title {
					margin-left: 0.5rem;
					// width:8rem;
				}

				.icon {
					margin-right: 0.3rem;
					background-image: image("icons/Group 2064.svg");
				}
				&.active .icon {
					background-image: image("icons/Group 2071.svg");
				}
			}
			&:nth-child(2) {
				padding-right: 1.3rem;
				padding-left: 3.5rem;
				.sw-title {
					margin-right: 0.5rem;
					// width:10rem;
				}

				text-align: right;
				justify-content: flex-end;
				.icon {
					margin-left: 0.3rem;
					background-image: image("icons/Group 2074.svg");
				}
				&.active .icon {
					background-image: image("icons/Group 2067.svg");
				}
			}

			&.active {
				background-color: $color-primary;
				color: #fff;
			}

			&--small {
				min-width: 60px;
			}
		}
	}
}
