.existing-user-selection-dialog {
	$border-thin-color: #cecece;
	max-width: 92rem;
	width: 92rem;
	.close-conainer {
		text-align: right;
		button {
			padding-right: 1.5rem;
			padding-top: 1rem;
		}
	}
	section {
		padding: 3rem 5.2rem;
		font-size: 1.6rem;
		h1 {
			font-size: 2.2rem;
		}
		.btn {
		}
		.message {
			color: #000;
			margin-top: 1.6rem;
			margin-bottom: 4.6rem;
		}

		.or {
			text-align: center;

			margin-bottom: 1.6rem;
		}

		.sett-row {
			display: flex;
			margin-bottom: 2.7rem;
			color: #000;

			font-size: 1.4rem;
			align-items: flex-start;
			margin-left: -0.9rem;
			margin-right: -0.9rem;
			.sett-col {
				flex: 1;
				padding: 0 0.9rem;
				max-width: 33.33%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
			}

			.form-input-group {
				label {
					font-size: 1.4rem;
					color: #000;
					position: relative;
				}
				&.required {
					label:after {
						font-size: 1.6rem;
						color: #000;
						content: "*";
						position: absolute;
						right: -1rem;
						top: -0.3rem;
					}
				}
			}
		}

		table {
			width: 100%;
			td,
			th {
				width: 20rem;
				text-align: center;
				padding: 2rem 1rem;
			}

			.email {
				width: 29rem !important;
			}

			.border-top {
				border-top: 1px solid $border-thin-color;
			}
			.border-bottom {
				border-bottom: 1px solid $border-thin-color;
			}
			.border-right {
				border-right: 1px solid $border-thin-color;
			}
			.border-left {
				border-left: 1px solid $border-thin-color;
			}
		}

		.buttons {
			text-align: center;
			margin-top: 3rem;
			.btn {
				width: 15rem;
			}
		}
	}
}
