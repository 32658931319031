.big-switch-container {
	display: flex;

	.switch {
		height: 3.8rem;
		border-radius: 1.9rem;
		border: 0.1rem solid #c8c8c8;
		display: flex;
		align-items: center;
		overflow: hidden;
		.sw-option {
			height: 3.8rem;
			flex: 1;
			padding-top: 0.4rem;
			padding-bottom: 0.4rem;
			display: flex;
			align-items: center;
			color: #c8c8c8;
			cursor: pointer;
			border-radius: 1.9rem;
			transition: all 250ms ease-in-out;
			white-space: nowrap;

			justify-content: center;
			padding-left: 3rem;
			padding-right: 3rem;

			&.active {
				background-color: $primary-color;
				color: #fff;
			}
		}
	}
}
