*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

body {
	text-rendering: optimizeSpeed;
}

// img,
// picture {
// 	max-width: 100%;
// }

button {
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none;
}

button,
a,
input,
select,
textarea {
	font: inherit;
	color: inherit;
}

ul,
li,
ol {
	list-style: none;
}
