.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__input:focus {
	outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
	display: block;
	position: absolute;

	border: 1px solid #e8e9ee;
	background-color: #fff;
	font-family: Helvetica, sans-serif;
	color: #565656;
	font-size: 1.2rem;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 1rem 2rem;
}

.react-autosuggest__suggestion:not(:first-child) {
	border-top: 1px solid #e8e9ee;
}

.react-autosuggest__suggestion--focused {
	background-color: #0c7eaf;
	color: #fff;
}

.suggestion-content {
	display: flex;
	align-items: center;
	background-repeat: no-repeat;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #f4f4f4;
}
