.files-list {
	min-height: 0;
	display: flex;
	flex-direction: column;
	flex: 1 1;
	.table-wrap {
		padding: 0 1.6rem 0 0.62rem;
	}
	.file-uploader-container {
		height: 28rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.data-table {
		.dt-row {
			height: 6.5rem;
		}
		.shinkView {
			height: 3.5rem;
		}
		.dt-header-row {
			& > div {
				padding: 1rem 0.5rem;
			}
		}

		.signatures-col {
			flex-basis: 20rem;
			text-align: center;
		}
		.check-col {
			flex-basis: 5.5rem;
			text-align: right;
		}
		.actions-col {
			flex-basis: 4rem;
			text-align: center;
		}
		.name-col {
			flex: 1;
			flex-shrink: 0;
			.icon {
				margin-right: 1rem;
				img {
					height: 2.5rem;
				}
			}
		}
		.watermark-col {
			flex-basis: 12rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.search-file-container {
			margin-left: 3.5rem;
			flex: 1;
			& > span {
				position: relative;
				display: inline-block;
				width: 81%;
				box-sizing: border-box;
				&:after {
					content: "";
					position: absolute;
					background-image: image("icons/Path 766.svg");
					height: 1.65rem;
					width: 1.65rem;
					right: 1.2rem;
					top: 0.94rem;
					z-index: 11;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

			input {
				border: 1px solid #e9e9e9;
				padding: 0.7rem 3.3rem 0.7rem 1rem;
				line-height: 1;
				font-size: 1.2rem;
				border-radius: 0.3rem;
				width: 100%;
				&:focus {
					outline: none;
				}
			}
		}
		.progress-container {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: flex-end;
			.progress-wrap {
				margin: 0 1.74rem;
				width: 10rem;
			}
			.signatures-count {
				color: #757575;
				font-size: 1.1rem;
				margin-bottom: 0.4rem;
			}
			.icon {
				width: 3rem;
				cursor: pointer;
				text-align: center;
				height: 2.3rem;
			}
		}
	}
}

.signers-popup {
	position: relative;
	.close-signers-popup {
		position: absolute;
		right: 1.5rem;
		top: 1.5rem;
	}
	.signers-head {
		padding: 2.1rem 3rem;
		font-size: 1.4rem;
		color: #757575;
		.count {
			margin-right: 1.5rem;
		}
		img {
			height: 1.8rem;
		}
	}
	.signers-list {
		padding: 0 4.6rem;
		.signers-line {
			display: flex;
			margin-bottom: 2rem;
			font-size: 1.2rem;
			color: #000;
			.name {
				width: 18rem;
			}
			.status {
				margin-left: auto;
			}
		}
	}
}

// General style for the small files dialog like move, send, duplicate
.file-small-dialog-container {
	// padding: 2.8rem 7.2rem;
	font-size: 1.4rem;
	color: #000;
	.duplicate-container {
		padding: 2.8rem 7.2rem;
	}
	.send-container {
		font-size: 1.2rem;
		padding: 0 4rem;

		padding-top: 2.6rem;
		color: #757575;
		textarea {
			border: 1px solid $gray2;
			border-radius: 0.3rem;
			width: 100%;
			min-height: 13.6rem;
			padding: 0.9rem 1.6rem;
			margin-bottom: 0.8rem;
		}
	}
}
