.header-tabs-container {
	padding: 0 1.3rem;
	display: flex;
	justify-content: space-between;
	height: 7rem;
	margin-top: 1.7rem;

	.tab {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		color: #4c4c4d;
		flex: 1 1;
		text-align: center;

		font-size: 1.7rem;
		text-decoration: none;
		position: relative;
		transition: all 0.2s;
		padding-top: 6px;
		height: calc(100% - 6px);
		&:hover {
			//background-color: #7a7c88;
			text-decoration: underline;
			letter-spacing: 1px;
		}
		&.active {
			border-top-left-radius: 1rem;
			border-top-right-radius: 1rem;
			background: $background1;
			box-shadow: 0px 13px 0px 0px $background1, -3px 3px 6px $box-shadow-color-2, 3px 3px 6px $box-shadow-color-2;
			border: 1px solid $color1;
			border-bottom: none;
			position: relative;
			font-weight: bold;
			text-decoration: underline;
			letter-spacing: 1px;

			&::before,
			&::after {
				content: "";
				height: 8px;
				border: 1px solid $color1;
				// background: #ffffff;
				position: absolute;
				bottom: -7px;
				border-top: 0;
				width: 200000px;
				box-shadow: 4px 4px 1px $background1;
			}

			&::after {
				border-right: 0;
				border-bottom-left-radius: 1rem;
				left: 100%;
				// width: var(--header-after-border-width);
			}
			&::before {
				border-left: 0;
				border-bottom-right-radius: 1rem;
				right: 100%;
			}
		}

		&:not(.active) + .tab:not(.active):after {
			content: "";
			display: block;
			width: 1px;
			background: $color1;
			height: 3rem;
			position: absolute;
			top: 0.6rem;
			left: 0;
			transform: translate(0, 50%);
		}
	}
}
