.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding: 2rem;
    margin: 2rem;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: #FC9F30;
    }
}

.detailsContainer {
    border-top: 1px solid #D0D4E4;
    border-bottom: 1px solid #D0D4E4;
    width: 80%;
    padding: 2rem 0;
    margin: 1rem 0;
}

.detail {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    h3 {
        font-size: 1.8rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }

    ;

    p {
        margin: 0 0 0 1rem;
        padding: 0;
    }
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    margin-top: 4rem;
}