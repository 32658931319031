.tableContainer {
    /* background-color: blueviolet; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

/* table {
    border-spacing: 0;
    border-collapse: separate;
    border: 2px solid green;
    border-radius: 25px !important;
    font-size: 1.6rem;
    max-height: 12rem;
    width: 100%;
} */

thead {
    position: sticky;
    font-weight: 300;
    background-color: #f0f1ff;
}

tbody {
    overflow: scroll;
}

td {
    padding: 1rem;
    white-space: nowrap;
}

th {
    border-top: 1px solid #d0d4e4;
    margin-top: 10.4rem;
    white-space: nowrap;
}

.headerCell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.6rem 0;
    /* padding: 1.6rem 0 1.6rem 0; */
    width: 80%;
    height: 100%;
}

/* exclude first cell border */
/* thead:first {
    border-right: none;
    background-color: red;
    border-radius: 25px;
}

thead:last-child {
    border-right: none;
    background-color: aqua;
} */

.nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.headerDivider {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.actions {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding: 0.6rem 0;
    margin: 0 0 0 1rem;
    justify-content: space-between;
    align-items: center;
}

.table {
    /* border-radius: 25px; */
    border-spacing: 0;
    border-collapse: separate;
    /* border: 1px solid #D0D4E4; */
    border-radius: 20px;
    max-height: 12rem;
    width: 100%;
    position: relative;
    box-shadow: 0.2rem 0.1rem 0.3rem 0px #c9cad066;
}

table tr th,
table tr td {
    /* border-top: 1px solid #D0D4E4; */
    border-right: 1px solid #d0d4e4;
    border-bottom: 1px solid #d0d4e4;
    padding: 0 2rem;
}

table tr td {
    padding: 0 2rem;
    height: 4rem;
}

table tr td:first-child {
    border-left: 1px solid #d0d4e4;
}

table tr:last-child td:first-child {
    border-left: 1px solid #d0d4e4;

    border-bottom-left-radius: 20px;
}

table tr:last-child td:last-child {
    /* border: 1px red solid; */
    border-bottom-right-radius: 20px;
}

table th:last-child {
    /* border: 1px red solid; */
    border-top-right-radius: 20px;
}

table th:first-child {
    border-left: 1px solid #d0d4e4;
    border-top-left-radius: 20px;
}

.searchBar {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    align-items: center;
    justify-content: space-around;
}

.searchBtn {
    margin-left: 2px;
}

/* .filterTable {
    border: 1px solid aqua;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
} */

.filterTable {
    background-color: #ffffff;
    box-shadow: 0 4px 8px #d0d4e4;
    border: 2px solid #ececec;
    padding: 1.6rem;
    min-width: 25rem;
    width: 58.7rem;
    position: absolute;
    right: 10rem;
    z-index: 10000;
    cursor: default;
}

.filterParams {
    display: flex;
    flex-direction: row;
}

.filterElm {
    margin: 2rem 0.6rem;
}

.filterButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* margin-top: 1rem; */
}

.addBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.6rem;
}

.filterButtons button {
    margin-left: 1rem;
}

.filterLevel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.filterLevel p {
    margin-right: 0.6rem;
}

.andFilter {
    width: 8rem;
}

.allFilters {
    /* border: 1px solid blueviolet; */
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.tableRow:hover {
    box-shadow: 4px 0px 12px #4d4d4d40;
    transition: box-shadow 0.2s;
}

.tableRow:last-child:hover {
    border-radius: 0 0 25px 25px;
}

.emptyRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.4rem;
}

.pageNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 0 0.8rem;
    width: 3rem;
    height: 3rem;
    margin: 0.4rem;
    cursor: pointer;
    box-shadow: 0.1rem 0.1rem 0.1rem 0 #c9cad066;
}

.pageNumber:hover {
    background-color: #d0d4e4;
}
