.dashboard {
	$border-thin-color: #cecece;
	flex: 1;

	.btn {
		width: 18.6rem;
	}

	.main-container {
		background-color: #fff;
		padding-left: 3rem;
		padding-right: 2.2rem;
		position: relative;
		padding-top: 2.7rem;
		padding-bottom: 3.4rem;
		border-radius: 0.5rem;
		box-shadow: 0px 0.1rem 0.1rem #00000026;
		border: 1px solid #f1f1f1;
		display: flex;

		// background-image: image("other/BACKGROUND WAVE2100.png");
		// background-repeat: no-repeat;
		// //background-size: 100%;

		// background-position: left -20rem;
		min-width: 0;
		flex-wrap: wrap;

		@include lt-md {
			padding: 1rem;
		}
		.box {
			// flex: 1;
			padding: 1.8rem;
			flex: 1 0 50%;
			// &.small-box {
			//   max-width: 42.5%;
			//   min-width: 42.5%;
			// }
			.box-inner {
				box-shadow: 0px 2px 3px 1px $box-shadow-color;
				height: 37.5rem;
				border-radius: 0.5rem;
				background-color: #fcfcfc;
				padding: 2.7rem 2.1rem;
				display: flex;
				flex-direction: column;

				&.yellow {
					// background-image: image("other/Rectangle 2@2x.png");
					// background-size: 100% 100%;
					// background-position: right top;
					// background-repeat: no-repeat;
					background: $background1;
					border: 1px solid $color1;
					display: flex;
					flex-direction: column;
					.no-data-message-container {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						.no-data-message {
							font-size: 1.8rem;
							text-align: center;
							margin-top: 3rem;
						}
					}
				}

				.box-title {
					font-size: 2.8rem;
					color: $primary-color;
					display: block;
					display: flex;
					align-items: center;
					img {
						margin-right: 2rem;
						height: 5rem;
					}
				}
			}
			.company-profile-sections {
				margin-top: auto;
				padding-left: 4.3rem;
				padding-right: 4.3rem;
				padding-bottom: 4.3rem;
				.company-logo {
					height: 5.5rem;
				}
				.sett-row {
					display: flex;
					margin-bottom: 2.7rem;
					margin-top: 3rem;
					color: #000;

					font-size: 1.4rem;
					align-items: flex-start;
					margin-left: -0.9rem;
					margin-right: -0.9rem;
					.sett-col {
						flex: 1;
						padding: 0 1.5rem;
						max-width: 33.33%;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						.ro-value {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
			}

			.dataroom-section {
				align-self: center;
				flex: 1;
				display: flex;
				align-items: center;
				.other-info {
					// margin: 3.7rem 3rem;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin-bottom: 0;
					.info-line {
						display: flex;
						padding: 1.9rem 4rem;
						width: 46rem;
						& + .info-line {
							border-top: 1px solid #e1e1e1;
						}

						.info-icon {
							margin-right: 1.6rem;
						}
						.info-counter {
							margin-left: auto;
							background-color: #e28181;
							color: #fff;
							font-size: 1.5rem;

							height: 3rem;
							width: 3rem;

							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						&:first-child {
							padding-top: 0;
						}
						&:last-child {
							padding-bottom: 0;
						}
					}
				}
			}

			.captable-section {
				display: flex;
				// padding: 0 10rem;
				margin-top: 3rem;
				& > div {
					flex: 1;

					& + div {
						border-left: 1px solid #e1e1e1;
					}
					canvas {
						width: 100% !important;
					}
				}

				h2 {
					font-size: 2rem;

					text-align: center;
				}
				h3 {
					font-size: 1.6rem;

					text-align: center;
				}
			}

			.options-section {
				// width: 58.4rem;

				align-self: center;
				// margin-right: 13.7rem;
				margin-top: -2.5rem;
				width: 100%;

				h2 {
					font-size: 2rem;

					text-align: center;
					margin-bottom: 3rem;
				}

				.progress-wrap {
					width: 80%;
					margin: auto;

					position: relative;
					margin-bottom: 3rem;

					.progress {
						height: 2rem;
						border-radius: 3rem;
						background-color: #c8c8c8;
					}
					.bg-Exercisable {
						background-color: $color1 !important;
						margin-right: 0.3rem;
					}
					.bg-Unvested {
						background-color: $color3 !important;
						margin-right: 0.3rem;
					}

					.bg-Saleable {
						background-color: $color4 !important;
						margin-right: 0.3rem;
					}

					.bg-sold {
						background-color: $color2 !important;
					}

					.poolBarLabels {
						width: 100%;
						display: flex;
						justify-content: space-between;

						.label {
							margin-top: 0.82rem;
							margin-left: 2.8rem;
							margin-right: 2.8rem;
							font-size: 1.4rem;

							.info {
								color: $color2 !important;
							}
							.warning {
								color: $color3 !important;
							}

							.Exercisable {
								color: $color1 !important;
							}
							.Unvested {
								color: $color3 !important;
							}

							.Saleable {
								color: $color4 !important;
							}

							.sold {
								color: #e2bd54 !important;
							}

							.text {
								letter-spacing: 0.48px;

								font-size: 1.2rem;
							}
							.amount {
								letter-spacing: 0px;
								color: #565656;
								font-size: 1.2rem;
							}
						}
					}
				}
			}
		}
	}
}
