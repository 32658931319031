.room-settings-container {
	padding-bottom: 5rem;
	.close-conainer {
		text-align: right;
		button {
			padding-right: 1.5rem;
			padding-top: 1rem;
		}
	}
	.actions {
		text-align: right;
		padding: 0 7.3rem;
		button {
			text-decoration: underline;
			color: #757575;
			font-size: 1.4rem;
			&:hover {
				text-decoration: none;
			}
			& + button {
				margin-left: 4.5rem;
			}
		}
	}
	.title {
		font-size: 2.2rem;
		color: $primary-color;
		position: relative;
		padding-left: 2.6rem;
		margin-top: 1.4rem;
		&::before {
			content: "";
			position: absolute;
			left: -1rem;
			top: 50%;
			margin-top: -1.2rem;
			height: 2.3rem;
			width: 2.1rem;
			background-color: $primary-color;
		}
	}
	.room-settings {
		padding: 0 6.3rem;

		.sett-row {
			display: flex;
			margin-bottom: 2.8rem;
			color: #000;

			font-size: 1.4rem;
			align-items: center;
			.margin-right {
				margin-right: 6.5rem;
			}
			.sett-col-1 {
				flex: 1;
			}
			.sett-col-2 {
				flex-basis: 21.2rem;
				text-align: center;
			}
		}
		.access-description {
			padding-right: 0.5rem;
			margin-top: 1.6rem;
			p {
				position: relative;
				padding-left: 3rem;
				font-size: 1.2rem;
				color: #565656;
				margin-bottom: 0rem;
				padding-bottom: 0.8rem;

				img {
					position: absolute;
					max-width: 1.7rem;
					left: 0;
					// top: 0.5rem;
				}
			}
		}
		textarea,
		input[type="text"] {
			border: 1px solid $gray2;
			border-radius: 0.3rem;
			width: 100%;
		}
		textarea {
			min-height: 7.7rem;
			padding: 0.9rem 1.6rem;
		}
		input[type="text"] {
			padding: 0.8rem 1.4rem;
		}
		.buttons {
			text-align: center;
			padding-top: 4.6rem;
			.btn {
				width: 14.4rem;
			}
		}
	}
}
