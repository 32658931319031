.toolbar {
	margin-bottom: 1.3rem;
	background-color: #fff;
	padding-left: 3.36rem;
	padding-right: 3.36rem;
	position: relative;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border-radius: 0.5rem;
	box-shadow: $box-shadow-card1;
	border: 0.1rem solid #f1f1f1;
	display: flex;
	align-items: center;
	// &::before {
	//   content: "";
	//   position: absolute;
	//   left: 0;
	//   top: 27.5px;
	//   margin-top: -1.6rem;
	//   height: 3.2rem;
	//   width: 2.15rem;
	//   background-color: #3e4049;
	// }
	.title {
		font-size: 2.6rem;
		display: flex;
		align-items: center;
		min-width: 17rem;
	}
}
