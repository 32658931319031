// -----------------------------------------------------------------------------
// This file contains styles that are specific to the overview page.
// -----------------------------------------------------------------------------
.overview-page {
	flex: 1;
	.toolbar {
		.filters {
			margin-left: auto;
			background: $background1;
			width: 36.4rem;
			height: 4.6rem;
			border-radius: 0.2rem;
			display: flex;
			align-items: center;
			padding: 1.3rem 2rem 1.3rem 1.6rem;
			line-height: 1;
			font-size: 1.4rem;
			margin-top: 0;
			display: flex;
			justify-content: space-between;
			.icon {
				display: block;
				padding-right: 1.4rem;
				border-right: 1px solid #fff;
				// margin-right: 3rem;
				img {
					height: 2rem;
					width: 1.8rem;
				}
			}
			.filter {
				display: flex;
				align-items: center;
				// margin-right: 3.6rem;
			}
		}
	}

	.rooms-container {
		display: flex;
		flex-wrap: wrap;
		gap: 1.3rem;
		//margin:0 -15px;
		//justify-content: space-between;
		// margin-top: 2.2rem;

		.room-box {
			border-radius: 0.5rem;
			box-shadow: 0px 0.1rem 0.1rem #00000026;
			border: 0.1rem solid #f1f1f1;
			padding: 1.8rem 0;
			padding-bottom: 0;
			background-color: #fff;
			position: relative;
			flex: 0 1 50rem;
			// margin: 0 5.4rem 0 0;
			// width: 33.3%;
			// width: calc(1 / 3 * 100% - (1 - 1 / 3) * 5.4rem);
			// margin-bottom: 3.5rem;
			min-height: 40rem;
			transition: all 100ms ease-in;
			display: flex;
			flex-direction: column;
			@include lt-md {
				flex: 1 1 30rem;
			}
			&.clickable {
				cursor: pointer;
				&:hover {
					//    transform: scale(1.02);
					background: #fbfbfb;
					// box-shadow: 0px 0rem 1rem #00000026;
				}
			}

			&:nth-child(3n) {
				margin-right: 0;
			}

			.expireDate {
				text-align: right;
				padding-right: 3.6rem;
				font-size: 1.2rem;
				margin-bottom: 0.74rem;
				color: #000;
			}
			.title {
				padding-left: 2.7rem;

				font-size: 2.2rem;
				line-height: 1.1;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 1rem;
					margin-top: -1.15rem;
					height: 2.3rem;
					width: 1.4rem;
					background-color: #3e4049;
				}
				.memebers-count {
					font-size: 1.6rem;
					margin-left: 1.3rem;

					color: #757575;
				}
			}

			.details {
				margin: 4rem 6.8rem;
				.description-text {
					border: 1px solid #e2e2e2;
					height: 12.1rem;
					border-radius: 0.3rem;
					padding: 0.77rem 2rem;
					color: #000;
					font-size: 1.563rem;
					word-break: break-all;
				}
				.other-info {
					margin: 3.7rem 3rem;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin-bottom: 0;
					.info-line {
						display: flex;
						padding: 1.9rem 0;
						width: 30.7rem;
						& + .info-line {
							border-top: 1px solid #e1e1e1;
						}

						.info-icon {
							margin-right: 1.6rem;
						}
						.info-counter {
							margin-left: auto;
							color: #e28181;
							font-size: 1.6rem;
						}

						&:first-child {
							padding-top: 0;
						}
						&:last-child {
							padding-bottom: 0;
						}
					}
				}
			}

			.new-room-buttons-container {
				display: flex;
				flex-wrap: wrap;
				margin: 0 1.5rem;
				.new-room-button {
					width: 50%;
					padding: 1.5rem;
					display: flex;
					button {
						border: 1px solid #e1e1e1;
						border-radius: 0.3rem;
						display: block;
						padding: 2.6rem 2.7rem;

						font-size: 1.5rem;
						color: #000;
						text-decoration: none;
						flex: 1;
						height: 100%;
						background-color: #fff;
						text-align: left;
						.newRoomText {
							vertical-align: middle;
							position: relative;
							top: 11px;
						}
						img {
							margin-right: 0.8rem;
							height: 4rem;
							width: 4rem;
							float: left;
						}
						&:hover {
							background-color: #eeeeee;
						}
					}
				}
				.new-room-button-disabeld {
					width: 50%;
					padding: 1.5rem;
					display: flex;
					button {
						opacity: 0.3;
						border: 1px solid #e1e1e1;
						border-radius: 0.3rem;
						display: block;
						padding: 2.6rem 2.7rem;

						font-size: 1.5rem;
						color: #000;
						text-decoration: none;
						flex: 1;
						height: 100%;
						background-color: #fff;
						text-align: left;
						.newRoomText {
							vertical-align: middle;
							position: relative;
							top: 11px;
						}
						img {
							margin-right: 0.8rem;
							height: 4rem;
							width: 4rem;
							float: left;
						}
					}
				}
			}
		}
	}

	.item-enter {
		opacity: 0.2;
	}
	.item-enter-active {
		opacity: 1;
		transition: opacity 500ms ease-in-out;
	}
	.item-exit {
		opacity: 1;
	}
	.item-exit-active {
		opacity: 0.2;
		transition: opacity 500ms ease-in-out;
	}
}
