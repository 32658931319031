@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap");

// ======= daniel =======

// Backgrounds
$background1: #f3f5fd;
$background2: #4d4d4d;
$background3: #51576e;
$table1: #c8d4fa;

// Colors
$green: #68ce61;
$color1: #8b96e9;
$color2: #f3d569;
$color3: #f76173;
$color4: #f2ab64;
$turquoise: #73d2cc;
$color1-secondary: #bdc3f1;
$box-shadow-color: #6565bc47;
$box-shadow-color-2: #6565bc29;
$field: #bac0d9;

$table: #d0d4e4;
$gray2: #dee0e7;

// Texts
$text1: #4d4d4d;
$text2: #979db5;
$text3: #acb2cc;
$text4: #828282;

$lightGray: #d9d9d980;

// Fonts
$font-alt: "allroundgothic";
$font-unica: "NeueHaasUnicaW1G";
$font-assistant: "Assistant";

$font-text-pro: "NeueHassTextPro";

$box-shadow-card1: 0px 3px 3px $box-shadow-color;
$box-shadow-card2: 0.2rem 0.2rem 0.2rem #6565bc99;

$box-shadow1: 0px 3px 6px #00000029;

// ======= daniel =======

$min-width: 1024px;

$body-background-color: #f9f9f9;
$primary-color: #3e4049;
$primary-color-light: #6a6d7c;
$text-color: #565656;
$highlight-color-light: #e9b478;
$highlight-color: #fc9f30;
$border-color: #cecece;
$color-primary: #9999ff;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Bootstrap overides
$font-size-base: 1.5rem;
$input-btn-padding-y-lg: 1rem !default;
$input-btn-padding-x-lg: 2rem !default;
$primary: $color1;
$success: #68ce61;
$danger: #f35669;
$warning: $highlight-color;
$input-placeholder-color: #c8c8c8;
$modal-lg: 69rem;
$modal-sm: 43.3rem;

///Datepicker overrides
$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $primary-color !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.5rem !default;
$datepicker__font-size: 1.2rem !default;
$datepicker__font-family: "Helvetica Neue", helvetica, arial, sans-serif !default;
$datepicker__item-size: 2.3rem !default;
$datepicker__margin: 0.8rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
    "small": 320px,
    "medium": 768px,
    "large": 1024px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/Assets/" !default;
