.new-memeber-modal {
	max-width: 74.5rem;
	.new-room-memeber-container {
		padding-bottom: 1.9rem;
		.close-conainer {
			text-align: right;
			button {
				padding-right: 1.5rem;
				padding-top: 1rem;
			}
		}

		.title {
			font-size: 2.2rem;
			color: $primary-color;
			position: relative;
			padding-left: 2.6rem;
			margin-top: 1.4rem;
			&::before {
				content: "";
				position: absolute;
				left: -1rem;
				top: 50%;
				margin-top: -1.2rem;
				height: 2.3rem;
				width: 2.1rem;
				background-color: $primary-color;
			}
		}
		.mobile-container {
			display: flex;
			& > input {
				width: 12rem !important;
			}
			.react-select__control {
				width: 8.3rem;
				margin-right: 0.9rem;
			}
		}
		// .switch-container {
		//     display: flex;
		//     justify-content: center;
		//     margin-bottom: 3rem;
		//     margin-top: 2.7rem;
		//     .switch {
		//         width: 28.3rem;
		//         height: 3.8rem;
		//         border-radius: 1.9rem;
		//         border: 0.1rem solid #c8c8c8;
		//         display: flex;
		//         align-items: center;
		//         overflow: hidden;
		//         .sw-option {
		//             height: 3.8rem;
		//             flex: 1;
		//             padding-top: 0.4rem;
		//             padding-bottom: 0.4rem;
		//             display: flex;
		//             align-items: center;
		//             color: #c8c8c8;
		//             cursor: pointer;
		//             border-radius: 1.9rem;
		//             transition: all 250ms ease-in-out;
		//             .icon{
		//                 display: inline-block;
		//                 width: 2.756rem;
		//                 height: 2.756rem;
		//                 background-size: contain;
		//                 background-repeat: no-repeat;
		//                 background-position: center;
		//             }
		//             &:nth-child(1) {
		//                 padding-left: 0.7rem;
		//                 .icon {
		//                     margin-right: 0.8rem;
		//                     background-image: image('icons/Group 2226.svg');
		//                 }
		//                 &.active .icon{
		//                     background-image: image('icons/Group 2219.svg');
		//                 }
		//             }
		//             &:nth-child(2) {
		//                 padding-right: 0.7rem;
		//                 text-align: right;
		//                 justify-content: flex-end;
		//                 .icon {
		//                     margin-left: 0.8rem;
		//                     background-image: image('icons/Group 2222.svg');
		//                 }
		//                 &.active .icon{
		//                     background-image: image('icons/Group 2229.svg');
		//                 }
		//             }

		//             &.active {
		//                 background-color: $primary-color;
		//                 color:#fff;
		//             }
		//         }
		//     }
		// }
		.settings {
			padding: 0 2.7rem;

			.sett-row {
				display: flex;
				margin-bottom: 2.7rem;
				color: #000;

				font-size: 1.4rem;
				align-items: flex-start;
				margin-left: -0.9rem;
				margin-right: -0.9rem;
				.sett-col {
					flex: 1;
					padding: 0 0.9rem;
					max-width: 33.33%;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
				}
			}
			.form-input-group {
				label {
					font-size: 1.2rem;
					color: #000;
					position: relative;
				}
				&.required {
					label:after {
						font-size: 1.6rem;
						color: #000;
						content: "*";
						position: absolute;
						right: -1rem;
						top: -0.3rem;
					}
				}
			}
			.view-photo-container {
				text-align: center;
				display: flex;
				justify-content: center;
				.btn-view-photo span {
					text-decoration: underline;
					margin-left: 1rem;
					color: #000;
				}
			}
			.upload-id-container {
				text-align: center;
				margin-top: 0.7rem;
				button span {
					text-decoration: underline;
					margin-left: 1rem;
					color: #c8c8c8;
				}
			}
			.access-description {
				padding-right: 0.5rem;
				margin-top: 1.6rem;
				width: 51rem;
				p {
					position: relative;
					padding-left: 3rem;
					font-size: 1.2rem;
					color: #565656;
					margin-bottom: 0rem;
					padding-bottom: 0.8rem;

					img {
						position: absolute;
						max-width: 1.7rem;
						left: 0;
						top: 0rem;
					}
				}
			}

			.buttons {
				text-align: center;
				padding-top: 4.6rem;
				.btn {
					width: 14.4rem;
				}
			}
		}
	}
}
