.card-container {
    opacity: 1;
}

.account-bg-setup-page {
    background-image: image("login/kube_f.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.mobile {
        padding: 0;
        background-image: unset;
    }
}

.account-bg-reg-success-page {
    background-image: image("login/zoom in hello page_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.mobile {
        padding: 0;
    }
}

.account-pages {
    position: relative;
    font-size: 1.6rem;
    background-color: #f5f5fd;
    height: 100%;
    padding: 4rem;

    &.tablet {
        align-items: center;
    }

    &.mobile {
        padding: 0 !important;
        height: 100%;
    }

    &.login {
        padding: 6rem;
    }

    .form-error {
        position: absolute;
        top: 5.5rem;
        left: 1rem;
        text-align: left;
        padding-top: 0.1rem;
        padding-left: 0.4rem;
        font-size: 1.3rem;

        &.mobile {
            top: 5.5rem;
        }
    }

    .card-footer {
        padding: 1.3rem 0 2.4rem 0;
        margin-top: 4rem;
        border-top: 1px solid #bdc3f1;
        border-radius: 0 0 30px 30px;
        background-color: #ffffff;

        &.mobile {
            background: unset;
        }
    }

    .alt-logo {
        position: absolute;
        top: 4rem;
        height: 5rem;
        width: 21rem;

        &.tablet {
            height: 5rem;
            width: 15rem;
        }

        &.mobile {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .goog-sign {
        .g-butt {
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
            color: #929292;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-shadow: 2px 2px 3px rgba(217, 217, 217, 0.4);
            border-radius: 30px;
            width: 100%;
            opacity: unset;
            height: 5rem;
            font-size: 2rem;
            &.mobile {
                height: 5.5rem;
            }
        }
    }

    .account-orange-butt {
        margin-top: 7rem;
        background: #fc9f30;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        font-weight: 500;
        color: #ffffff;
        height: 4.5rem;
        width: 100%;
        border: unset;
        opacity: unset;
        font-size: 1.6rem;
        height: 5rem;
        font-size: 2rem;

        &.mobile {
            height: 4.5rem;
        }

        &.first-phase {
            margin-top: 4rem;
        }

        &:not(:disabled):hover {
            background: #000066 !important;
            color: #ffffff !important;
        }

        &.devOnly {
            color: #929292;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-shadow: 2px 2px 3px rgba(217, 217, 217, 0.4);
        }
    }

    .account-orange-butt--disabled {
        cursor: not-allowed;
    }

    .account-orange-butt--no-gap {
        margin-top: 1rem;
    }

    .reset-password-butt {
        margin-top: 1rem;
    }

    .account-input input {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 2px 2px 3px rgba(217, 217, 217, 0.4);
        border-radius: 30px;
        height: 5rem;
        width: inherit !important;
        font-size: 1.6rem !important;

        &.mobile input {
            height: 5.5rem !important;
        }
        &:focus {
            border: 1px solid #8b96e9 !important;
        }
        &::placeholder {
            color: #4d4d4d61;
        }
    }

    .reset-pass-input-container {
        position: relative;
    }

    .account-input-password {
        &.mobile {
            width: 100% !important;
            margin-bottom: 2rem;
        }
    }

    .account-input {
        &.mobile input {
            height: 5.5rem !important;
        }
    }

    .otp-input {
        width: 100%;
    }

    .account-input-row-1 {
        margin-top: 3rem;
    }
    .account-input-row-2 {
        display: flex;
    }
    .or-sign {
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        color: #4d4d4d;
        display: flex;
        flex-direction: row;
        padding: 2rem 0;
    }
    .or-sign:before {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #e1e1e1;
        margin: auto;
        margin-right: 1rem;
    }
    .or-sign:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #e1e1e1;
        margin: auto;
        margin-left: 1rem;
    }
    .account-footer {
        display: flex;
        border-top: 1px solid #bdc3f1;
        justify-content: center;
        padding-top: 2rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
        color: #828282;
    }
    .account-reg-container {
        display: flex;
        height: 100%;
        position: relative;
        overflow: hidden;
        justify-content: space-between;
        padding: 2rem;

        .terms-cb-input {
            align-self: flex-start;
        }

        .terms-cb {
            font-size: 1.4rem;

            &.mobile {
                font-size: 1.2rem;
            }
        }

        .hide {
            visibility: hidden;
        }

        .terms-link {
            color: $color1;
        }

        &.mobile {
            padding: 0;
            width: 100%;
            height: 100%;
        }

        .success-msg-box {
            background: #ffffff;
            border: 3px solid #f5f5fd;
            box-shadow: 0px 4px 20px rgba(139, 150, 233, 0.2);
            border-radius: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            padding: 10rem 7rem;

            &.mobile {
                padding: 8rem 2rem;
                width: 90%;
            }

            .hello-user {
                font-weight: 600;
                font-size: 5rem;
                color: #4d4d4d;
                text-align: center;

                &.mobile {
                    font-size: 3rem;
                }
            }
            .welcome-altshare {
                font-weight: 600;
                font-size: 5rem;
                color: #8b96e9;
                text-align: center;

                &.mobile {
                    font-size: 3rem;
                }
            }
        }

        .account-page-content {
            max-width: 80rem;
            z-index: 2;
            position: relative;
            opacity: 1;

            &.mobile {
                display: none;
            }

            .title-join {
                padding-top: 11rem;
                font-family: $font-alt;
                font-weight: 600;
                font-size: 6rem;
                line-height: 7.2rem;

                color: #4d4d4d;
            }
            .title-join-hidden {
                display: none;
            }
            .title-promo {
                margin-top: 1.5rem;
                font-weight: 500;
                font-size: 3.5rem;
                line-height: 5rem;
                color: #8b96e9;
            }
            .list-promo {
                margin-top: 3.5rem;

                font-style: normal;
                font-weight: 400;
                font-size: 3rem;
                line-height: 4rem;
                color: #4d4d4d;
                list-style: none;
                padding: unset;

                .promo--bold {
                    font-weight: 600;
                    margin-inline: 0.7rem;
                    white-space: nowrap;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-top: 2rem;
                    white-space: nowrap;

                    img {
                        vertical-align: text-top;
                        padding-right: 2rem;
                    }
                }
            }
        }
        .g2-widget {
            position: absolute;
        }
        .img-inverted {
            position: absolute;
            width: 78rem;
            left: 31%;
            bottom: 0;
            z-index: 1;

            &.tablet {
                display: none;
            }

            &.mobile {
                display: none;
            }
        }
    }

    .input-error-container {
        margin: 5rem 0 1.5rem 0;
        position: relative;
        width: 100%;
    }

    &.account-bg-login-page {
        background-image: image("login/login-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .form-login-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 63.5rem;

            &.animation {
                animation: resize 1s ease-out forwards;
            }

            &.tablet {
                width: 85%;
            }

            &.mobile {
                width: 100%;
            }
        }
    }

    .form-account-box {
        z-index: 2;
        background: #ffffff;
        border: 1px solid #f5f5fd;
        box-shadow: 0px 4px 16px 6px rgba(139, 150, 233, 0.2);
        border-radius: 32px;
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        height: 100%;
        flex: 0 0 60rem;

        &.login-box {
            max-width: 100%;
        }

        &.mobile {
            background: unset;
            border: unset;
            box-shadow: unset;
            border-radius: unset;
            overflow-x: hidden;
            flex: 0 1 100%;
            height: fit-content;
        }

        .verification-msg-overlay {
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            position: fixed;
            background-color: #f5f5fddb;

            &.mobile {
                background-color: #f3f5fd;
            }

            .verification-msg {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
                border: 3px solid #f5f5fd;
                box-shadow: 0px 4px 20px rgba(189, 195, 241, 0.4);
                border-radius: 30px;
                z-index: 9;

                &.mobile {
                    width: 85%;
                }
            }
        }
        .form-header {
            .form-progress {
                padding-top: 2rem;
                width: 7rem;
                height: auto;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .form-title {
                margin-top: 6rem;
                font-style: normal;
                font-weight: 600;
                font-size: 3rem;
                color: #4d4d4d;
                font-family: $font-alt;
                white-space: nowrap;

                &.mobile {
                    font-size: 2.5rem;
                }
            }
            .form-sub-title {
                font-style: normal;
                font-weight: 400;
                font-size: 2rem;
                color: #4d4d4d;

                &.mobile {
                    font-size: 1.8rem;
                }
            }
        }

        .setup-form {
            margin-top: 6rem;
            padding-inline: 7.8rem;

            &.mobile {
                margin-top: 4.2rem;
                padding-inline: 3rem;
            }

            .select-role .react-select__control {
                background: #ffffff;
                border: 1px solid #d9d9d9 !important;
                box-shadow: 2px 2px 3px rgba(217, 217, 217, 0.4) !important;
                border-radius: 30px !important;
                height: 4.5rem !important;
            }
            .select-role {
                &.input-error .react-select__control {
                    border: 1px solid #f17777 !important;
                }
            }
            .select-role {
                &.mobile .react-select__control {
                    height: 5.5rem !important;
                }
            }
            .select-role .react-select__placeholder {
                font-size: 1.6rem;

                color: #4d4d4d61;
                padding-left: 0.75rem;
            }
            .select-role .value {
                text-align: left;
            }
        }

        .login-account-form {
            padding: 5rem 12rem 4rem 12rem;
            opacity: 1;

            &.mobile {
                opacity: 1;
                padding: 5rem 3rem 4rem 3rem;
            }

            &.tablet {
                padding: 5rem 15rem 4rem 15rem;
            }

            .account-forgot-pass {
                text-align: right;
                margin-top: 1rem;
                padding-right: 1.6rem;
            }
            .or-sign {
                padding: 3.5rem 0;
            }
            &.otp {
                padding-bottom: 16rem;
            }
        }

        .form-login-title {
            padding-top: 6rem;
            font-style: normal;
            font-weight: 500;
            font-size: 4.5rem;
            text-align: center;
            color: #8b96e9;
            white-space: nowrap;
            // font-family: $font-alt;

            &.otp {
                font-size: 2.6rem;
                white-space: normal;
                padding-inline: 8rem;
            }

            &.mobile {
                font-size: 2.8rem;
            }
        }

        .lock-img {
            padding: 4rem 0 2rem 0;
        }
        .create-account-form {
            padding: 9rem 8rem 4rem 8rem;
            &.mobile {
                padding: 4rem 3rem 4rem 3rem;
            }

            .continue-msg {
                font-style: normal;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 3rem;
                color: #828282;
            }
        }
    }
    .continue-msg-no-border {
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        color: #828282;
        margin-top: 2rem;
    }

    .reset-password-box {
        padding: 0 0 0 0;
        height: 100%;

        &.password-changed {
            padding: 7rem;
            width: fit-content;
            align-items: center;
            margin: 0 5rem 0 6rem;
        }
    }

    .reset-password-img {
        margin: 4rem 0;
    }

    .reset-password-inner-box {
        padding-inline: 15rem;
        margin-bottom: 2.7rem;

        &.tablet {
            padding-inline: 5rem;
        }

        &.mobile {
            padding-inline: 0rem;
        }
    }

    .password-reset-inner-container {
        padding-inline: 12rem;
        padding-bottom: 4rem;

        &.tablet {
            padding-inline: 10rem;
        }

        &.mobile {
            padding-inline: 0;
        }
    }

    .verification-msg--mobile {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 3px solid #f5f5fd;
        box-shadow: 0px 4px 20px rgba(189, 195, 241, 0.4);
        border-radius: 30px;
        z-index: 9;
        width: 85%;
    }

    .password-input-icon {
        position: absolute;
        right: 1.5rem;
        cursor: pointer;
        height: 0.9rem;
        width: 2rem;
        top: 2.2rem;

        &.mobile {
            top: 2.5rem;
        }
    }

    .password-input-icon-opened {
        height: 2rem;
        width: 2.2rem;
        top: 1.7rem;

        &.mobile {
            top: 1.9rem;
        }
    }

    .input-label {
        position: absolute;
        top: 5.5rem;
        left: 1rem;
        color: #929292;
        font-size: 1.3rem;
        white-space: nowrap;
    }
}

.verifi-msg-footer {
    display: flex;
    border-top: 1px solid #bdc3f1;
    justify-content: center;
    padding-top: 2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #828282;
}

.account-verifi-container {
    padding: 3rem 0;
}

.account-verifi-msg {
    text-align: center;
    padding: 3rem 0rem;
    position: relative;

    &.mobile {
        padding: 2rem 0rem;
        margin: 0 2.4rem;
    }
    .verifi-msg-img {
        padding: 1rem 0;
    }

    .reg-modal-hello {
        font-style: normal;
        font-weight: 500;
        font-size: 3rem;
        text-align: center;
        color: #4d4d4d;
        padding-top: 1rem;
        font-weight: 600;
        font-family: $font-alt;
    }

    .reg-modal-welcome-to-altshare {
        color: #8b96e9;
        font-weight: 600;
        font-size: 3rem;
        font-family: $font-alt;
    }
    .reg-modal-img-mbile {
        padding: 3rem;
        padding-inline-start: 0.4rem;
    }
    .reg-modal-title {
        font-style: normal;
        font-weight: 600;
        font-size: 4.5rem;
        color: #8b96e9;

        &.mobile {
            font-size: 3.5rem;
        }
    }

    .reg-modal-msg {
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        text-align: center;
        color: #4d4d4d;

        &.tablet {
            font-size: 2rem;
        }

        &.mobile {
            font-size: 1.6rem;
        }
    }

    .reg-modal-log-in {
        margin-top: 3rem;
    }

    .reg-modal-to-mail {
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        text-align: center;
        color: #4d4d4d;
        word-break: break-all;
        margin: 0.5rem 0 1rem 0;
        font-weight: 800;
        &.tablet {
            font-size: 2rem;
        }

        &.mobile {
            font-size: 1.6rem;
        }
    }
    .reg-modal-resend {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        white-space: nowrap;
    }
}

.account-link {
    font-weight: 400;
    font-size: inherit;
    color: #8b96e9;
    white-space: nowrap;
    cursor: pointer;
}

.login {
    display: flex;
    height: 100%;
    overflow-y: hidden;

    .container-fluid {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-image: image("login/back1280.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;

        @media screen and (min-width: 1000px) and (max-width: 1920px) {
            background-image: image("login/back1280.jpg");
        }

        @media screen and (min-width: 1921px) and (max-width: 2560px) {
            background-image: image("login/back2560.jpg");
        }

        @media screen and (min-width: 2561px) and (max-width: 3840px) {
            background-image: image("login/back3840.jpg");
        }

        .loginBox {
            border-radius: 0.5rem;
            width: 100%;
            width: 112.8rem;
            height: 56.3rem;
            background-image: image("login/box1280100.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @media screen and (min-width: 1000px) and (max-width: 1920px) {
                background-image: image("login/box1280100.png");
            }

            @media screen and (min-width: 1921px) and (max-width: 2560px) {
                background-image: image("login/box2560100.png");
            }

            @media screen and (min-width: 2561px) and (max-width: 3840px) {
                background-image: image("login/box3840100.png");
            }

            .loginContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 58.4%;

                .mt-8 {
                    margin-top: 8rem;
                }

                .loginFields {
                    flex: 1;
                    white-space: nowrap;
                    width: 100%;
                    .password-contain {
                        padding-left: 10rem;
                    }
                    .login-row {
                        display: flex;
                        margin-bottom: 2.7rem;
                        color: #000;

                        font-size: 1.4rem;
                        align-items: flex-start;
                        justify-content: center;
                        margin-left: -0.9rem;
                        margin-right: -0.9rem;

                        .login-col {
                            flex: 1;
                            max-width: 22.3rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;

                            & + .login-col {
                                margin-left: 5.5rem;
                            }

                            .form-input-group {
                                display: flex;
                                align-items: flex-start;
                                flex-direction: column;
                            }
                        }
                    }

                    .login-row {
                        .link {
                            text-align: center;
                            width: 100%;
                            text-decoration: underline;
                            color: #000000;
                            opacity: 1;
                        }
                    }

                    .form-input-group {
                        label {
                            font-size: 1.5rem;
                            color: #000;
                            position: relative;
                            padding-left: 0;
                        }

                        &.required {
                            label:after {
                                font-size: 1.6rem;
                                color: #000;
                                content: "*";
                                position: absolute;
                                right: -1.5rem;
                                top: -0.3rem;
                            }
                        }

                        .text-input {
                            display: block;
                            padding-top: 0.1rem;
                            width: 100%;
                        }
                    }

                    .buttons {
                        text-align: center;
                        margin-top: 1.6rem;
                        height: 5rem;

                        .btn {
                            background: #f1d069 0% 0% no-repeat padding-box;
                            text-align: center;
                            letter-spacing: 0px;
                            color: #221f1f;
                            border-color: #f1d069;
                            height: 100%;
                        }
                    }
                }

                .title {
                    .icon {
                        display: flex;
                        place-content: center;
                        margin-top: 4.4rem;
                    }

                    .text {
                        font-size: 2.6rem;
                        margin-left: auto;
                        margin-right: auto;
                        letter-spacing: 0px;
                        color: #3e4049;
                        margin-top: 1.5rem;
                        text-align: center;
                    }
                }
            }

            .OTP {
                .title {
                    .icon {
                        margin-left: auto;
                        margin-right: auto;
                        width: 5.2rem;
                        margin-top: 4.4rem;
                    }

                    .text {
                        font-size: 2.6rem;
                        margin-left: auto;
                        margin-right: auto;
                        width: 40.8rem;
                        text-align: center;
                        letter-spacing: 0px;
                        color: #3e4049;
                        opacity: 1;
                        margin-top: 1.5rem;
                    }
                }

                .login-row {
                    margin-top: 5.1rem;

                    .login-col {
                        flex: 1;
                        padding: 0 0.9rem;
                        max-width: 100% !important;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .link {
                    width: 100%;
                    text-align: center;
                    text-decoration: underline;
                    letter-spacing: 0.45px;
                    color: #000000;
                    opacity: 1;
                }

                .bringUp {
                    position: relative;
                    top: -2.5rem;
                }

                .form-input-group {
                    label {
                        font-size: 1.2rem;
                        color: #000;
                        position: relative;

                        margin-left: 0.5rem;
                    }

                    &.required {
                        label:after {
                            font-size: 1.6rem;
                            color: #000;
                            content: "*";
                            position: absolute;
                            right: -1.5rem;
                            top: -0.3rem;
                        }
                    }
                }

                .text-input {
                    display: block;
                    width: 30rem;
                }
            }
        }
    }
}

.password-changed {
    width: fit-content;
    padding: 7rem !important;
    text-align: center;

    .password-changed-title {
        font-style: normal;
        font-weight: 600;
        font-size: 4.5rem;
        text-align: center;
        color: #8b96e9;
    }

    .password-changed-img {
        margin: 3.5rem 0 2.5rem 0;
    }

    .password-changed-des {
        color: #4d4d4d;
        text-align: center;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.4px;
    }

    .changed-title {
        font-size: 2rem;
        font-weight: 700;
    }

    a {
        color: #3cd33c;
        font-size: 2rem;
        font-weight: 700;
        text-decoration: underline;
    }
}

.login-select {
    border-radius: 35px !important;
    text-align: left;
    height: 5rem;
    box-shadow: 2px 2px 3px rgba(217, 217, 217, 0.4);
    font-size: 1.6rem;
    border-color: #d9d9d9;

    .select {
        border-radius: 35px !important;
        text-align: left;
        height: 5rem;
        box-shadow: 2px 2px 3px rgba(217, 217, 217, 0.4);
        font-size: 1.6rem;
        border-color: #d9d9d9;

        &:focus {
            border-color: #8b96e9 !important;
        }
    }
}

.login-select-mobile {
    padding-inline-start: 3rem;
}

.registerContainer {
    height: 100%;
}

.designedForMobileContainer {
    background-color: rgb(245, 245, 253);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999;

    .innerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 3px solid #f5f5fd;
        box-shadow: 0px 4px 20px rgba(189, 195, 241, 0.4);
        border-radius: 30px;
        z-index: 9;
        width: 85%;
        padding: 4.5rem 2.2rem;

        .contentContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-items: center;
            width: 100%;

            &__closeIcon {
                position: absolute;
                right: -1rem;
                top: -2.5rem;
                width: 3rem;
                height: 3rem;
                cursor: pointer;
            }

            &__title {
                font-style: normal;
                font-weight: 500;
                font-size: 2rem;
                text-align: center;
                color: #8b96e9;
                padding-top: 1rem;
                font-weight: 600;
                font-family: $font-alt;
            }

            &__subTitle {
                color: #8b96e9;
                font-weight: 600;
                font-size: 3rem;
                font-family: $font-alt;
                text-align: center;
            }

            &__img {
                width: fit-content;
                margin: 2rem 0;
            }

            &__text {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                text-align: center;
                color: #4d4d4d;
            }

            &__select {
                font-size: 3rem;
                margin: 4rem 0 2rem 0;
                white-space: nowrap;
            }

            &__button {
                background: #fc9f30;
                box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
                font-weight: 500;
                color: #ffffff;
                border: unset;
                font-size: 2rem;
                width: 100%;
                border-radius: 25px;

                &:not(:disabled):hover {
                    background: #000066 !important;
                    color: #ffffff !important;
                }
            }
        }

        .bottomContainer {
            text-align: center;

            .navToEmployeesWeb {
                margin-top: 0.5rem;

                &__link {
                    width: fit-content;
                    flex: 0;
                    margin-left: 0.5rem;
                    white-space: nowrap;
                }
            }
        }
    }
}
